<template>
  <div class="page-wrap">
    <div class="history_content">
      <!--广告-->
      <div class="advertisement_frame">
        <ul
          class="Advertisement"
          id="ad_history_top"
          style="display: none"
        ></ul>
      </div>
      <!--内容-->
      <div class="history-code">
        <ul class="choice">
          <li>
            <span class="title">{{ titles.xznf }}：</span>
            <ul class="choice-ul particularYear">
              <li
                v-for="(item, index) in years"
                :key="index"
                @click="getData(index)"
                :class="{ active: item.selected }"
              >
                <span class="">{{ item.name }}</span>
              </li>
            </ul>
          </li>

          <li>
            <span class="title">{{ titles.xzwz }}：</span>
            <ul class="choice-input choice-input-index clearfix">
              <li
                v-for="(item, index) in titles.weizhi"
                :key="index"
                @click="weiZhiClick(index)"
              >
                <div
                  data-value="0"
                  class="position_superior choose_superior"
                  :class="{ active: item.selected }"
                >
                  {{ item.name }}
                </div>
              </li>
            </ul>
            <div class="choice-fun">
              <button class="reduction" value="0" @click="weiZhiClear()">
                还原
              </button>
            </div>
          </li>
          <li>
            <span class="title">{{ titles.xztj }}：</span>
            <ul class="choice-input choice-input2 clearfix">
              <li
                v-for="(item, index) in titles.shengxiao"
                :key="index"
                @click="tiaoJianClick(index)"
              >
                <div
                  :data-value="item.name"
                  class="position_superior choose_superior"
                  :class="{ active: item.selected }"
                >
                  {{ item.name }}
                </div>
              </li>
            </ul>
            <div class="choice-fun">
              <button class="reduction" value="1" @click="tiaoJianClear()">
                还原
              </button>
            </div>
          </li>
        </ul>

        <div class="history-record">
          <table
            width="100%"
            border="1"
            align="center"
            cellpadding="0"
            cellspacing="0"
          >
            <tbody>
              <tr align="center" class="table-header">
                <td width="166">{{ titles.qsh }}</td>
                <td width="500">{{ titles.zm }}</td>
                <td width="98">{{ titles.tm }}</td>
                <td width="98">{{ titles.tmdsh }}</td>
                <td width="98">{{ titles.tmdx }}</td>
                <td width="98">{{ titles.zhdsh }}</td>
                <td width="98">{{ titles.zhdx }}</td>
              </tr>
              <tr
                v-for="(item, index) in datas"
                :key="index"
                :class="{ backAsh: index % 2 != 0 }"
              >
                <td>
                  <span class="colorChen">{{ item.draw }}</span
                  >&nbsp;{{ item.time }}
                </td>
                <td>
                  <ul class="theBall-ul">
                    <li v-for="(item2, index2) in item.zm" :key="index2">
                      <div
                        class="theBall-frame"
                        :class="{
                          opacityOne: !isSelected(index2, item2.shengxiao),
                        }"
                      >
                        <div class="theBall" :class="item2.color">
                          {{ item2.code }}
                        </div>

                        <span class="text-botton">{{ item2.shengxiao }}</span>
                      </div>
                    </li>
                  </ul>
                </td>
                <td class="numbre">
                  <div
                    class="theBall-frame"
                    :class="{
                      opacityOne: !isSelected(6, item.tm.sx),
                    }"
                  >
                    <div class="theBall" :class="item.tm.color">
                      {{ item.tm.code }}
                    </div>
                    <span class="text-botton">{{ item.tm.sx }}</span>
                  </div>
                </td>

                <td>
                  <span :class="{ colorChen: item.tmdsh == odd }">{{
                    item.tmdsh
                  }}</span>
                </td>
                <td>
                  <span :class="{ colorChen: item.tmdx == big }">{{
                    item.tmdx
                  }}</span>
                </td>
                <td>
                  <span :class="{ colorChen: item.zhdsh == odd }">{{
                    item.zhdsh + "(" + item.sum + ")"
                  }}</span>
                </td>
                <td>
                  <span :class="{ colorChen: item.zhdx == big }">{{
                    item.zhdx
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: {},
      datas: [],
      years: [],
      weiZhis: [],
      shengXiaos: [],
      big: "",
      odd: "",
    };
  },

  methods: {
    getTitle: function() {
      let url = "/HistorySevice/GetTitle";
      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;

          if (result.code == "1") {
            let title = this.Decrypt(result.list);

            this.titles = JSON.parse(title);
            this.big = this.titles.big;
            this.odd = this.titles.odd;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getData: function(index) {
      let year = this.years[index].name;
      for (let i = 0; i < this.years.length; i++) {
        this.years[i].selected = false;
      }
      this.years[index].selected = true;
      let url = "/HistorySevice/GetData?year=" + year;
      this.axios
        .get(url)
        .then((res) => {
          //console.log(res.data);
          let result = res.data;
          if (result.code == "1") {
            let data = this.Decrypt(result.list);
            //console.log(data);
            this.datas = JSON.parse(data);
            //console.log(this.datas);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    weiZhiClick(index) {
      this.titles.weizhi[index].selected = !this.titles.weizhi[index].selected;
      if (this.weiZhis.indexOf(index) == -1) {
        this.weiZhis.push(index);
      } else {
        let p = this.weiZhis.indexOf(index);
        this.weiZhis.splice(p, 1);
      }
    },
    weiZhiClear() {
      for (let i = 0; i < this.titles.weizhi.length; i++) {
        this.titles.weizhi[i].selected = false;
      }
      this.weiZhis = [];
    },
    tiaoJianClick(index) {
      this.titles.shengxiao[index].selected = !this.titles.shengxiao[index]
        .selected;
      let p = this.shengXiaos.indexOf(this.titles.shengxiao[index].name);
      if (p == -1) {
        this.shengXiaos.push(this.titles.shengxiao[index].name);
      } else {
        this.shengXiaos.splice(p, 1);
      }
    },
    tiaoJianClear() {
      for (let i = 0; i < this.titles.shengxiao.length; i++) {
        this.titles.shengxiao[i].selected = false;
      }
      this.shengXiaos = [];
    },
    isSelected(weizhi, shengxiao) {
      if (this.weiZhis.length == 0 && this.shengXiaos.length == 0) {
        return true;
      } else if (this.weiZhis.length == 0 && this.shengXiaos.length > 0) {
        if (this.shengXiaos.indexOf(shengxiao) != -1) {
          return true;
        } else {
          return false;
        }
      } else if (this.weiZhis.length > 0 && this.shengXiaos.length == 0) {
        if (this.weiZhis.indexOf(weizhi) != -1) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.weiZhis.indexOf(weizhi) != -1 &&
          this.shengXiaos.indexOf(shengxiao) != -1
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted: function() {
    let curyear = new Date().getFullYear();
    for (let i = curyear; i >= 2002; i--) {
      this.years.push({ name: i, selected: false });
    }
    this.getTitle();
    this.getData(0);
  },
};
</script>

<style scoped>
.history_content {
  margin: 0 auto;
  display: block;
}

.history_content .history-code {
  width: 100%;
  margin-top: 0px;
  padding: 20px;
  box-sizing: border-box;
  background: #fdfdfd;
}

.history_content .history-code .question {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 5px;
  zoom: 1;
}

.history_content .history-code .text-botton {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: -5px;
  margin-left: 3px;
}

.history_content .history-code .colorChen {
  color: #ee6d58;
}

.history_content .history-code .choice {
  width: 100%;
  overflow: hidden;
}

.history_content .history-code .choice li {
  width: 100%;
  height: 30px;
  line-height: 30px;
  clear: both;
}

.history_content .history-code .choice li .title {
  float: left;
  margin-top: 4px;
}
.history_content .history-code .choice li .choice-fun {
  display: inline-block;
  cursor: pointer;
  margin: 6px;
}

.history_content .history-code .choice li .choice-ul {
  width: 90%;
  margin-left: -40px;
  float: left;
  overflow: hidden;
}

.history_content .history-code .choice-ul li {
  float: left;
  width: 65.5px;
  text-align: center;
  background: #fff0eb;
  margin: 6px 6px;
  color: #ee6d58;
  border-radius: 3px;
  clear: none;
  cursor: pointer;
}

.history_content .history-code .choice-ul li:hover {
  background: #ec6337;
  color: #fff;
}

.history_content .history-code .choice-ul li.select,
.history_content .history-code .choice-ul li.active,
.history_content .history-code .choice-ul li:hover {
  background: #ec6337;
  color: #fff;
}

.history_content .history-code .choice-fun {
  display: inline-block;
  cursor: pointer;
  margin: 6px;
}

.history_content .history-code .choice-fun button {
  cursor: pointer;
  background: #41b45f;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  width: 65px;
  margin-top: -10px;
}

.history_content .history-code .choice-fun .reduction:hover {
  background: #30a04d;
}

.history_content .history-code .choice-input {
  width: auto;
  /*max-width: 89%;*/
  margin-left: -40px;
  float: left;
  overflow: hidden;
  color: #676767;
}

.history_content .history-code .choice-input .choose_superior {
  width: 64px;
  text-align: center;
  margin: 0px;
  border-radius: 3px;
  clear: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  color: #333;
}

.history_content .history-code .choice-input .choose_superior .icon_tick {
  background: url("/images/frame.png");
  margin-top: -5px;
  top: 1px;
}

.history_content .history-code .choice-input .choose_superior:hover {
  border-color: #ec6337;
  color: #ec6337;
}

.history_content .history-code .choice-input .choose_superior.active {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.history_content .history-code .choice-input .choose_superior:hover {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.history_content .history-code .choice-input li {
  float: left;
  margin: 6px;
  width: 60px;
  max-width: 60px;
  clear: none;
}

.history_content .history-code .choice-input li input {
  height: 25px;
  float: left;
  background: none;
  cursor: pointer;
}

.history_content .history-code .choice-input.choice-input2 .choose_superior {
  cursor: pointer;
  margin-right: 25px;
}

.history_content .history-code .history-record {
  width: 100%;
  margin-top: 10px;
}

.history_content .history-code .history-record table,
.history_content .history-code .history-record td,
.history_content .history-code .history-record th {
  border: 1px solid #ccc;
}

.history_content .history-code .history-record td,
.history_content .history-code .history-record th {
  border-bottom: none;
  border-top: none;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 5px 0;
}

.history_content .history-code .history-record .table-header {
  background: #e0e0e0;
  border: 1px solid #ccc;
}

.history_content .history-code .history-record .theBall-ul {
  width: auto;
  margin: 0 auto;
  display: table;
}

.history_content .history-code .history-record .theBall-ul li {
  float: left;
  margin: 3px 3px;
}

.history_content .history-code .history-record .history-attribute {
  margin-top: 20px;
}

.history_content .history-code .history-record .history-attribute table,
.history_content .history-code .history-record .history-attribute td,
.history_content .history-code .history-record .history-attribute th {
  border: 1px solid #ccc;
}

.history_content .projectile-frame {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
}

.history_content .disnone {
  display: none;
}

.history_content .cursor {
  cursor: pointer;
}

.history_content .opacityOne {
  opacity: 0.1;
}

.history_content .projectile-frame-back {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 550px;
  background: #fdfdfd;
  margin-left: -400px;
  margin-top: -255px;
}

.history_content .projectile-frame-back .colorWhei {
  color: #fff;
  background: #ec6337;
}

.history_content .projectile-frame-back .frame-title {
  position: relative;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  background: #ec6337;
}

.history_content .projectile-frame-back .frame-title .cose {
  position: absolute;
  width: 15px;
  height: 15px;
  right: 17px;
  top: 15px;
}

.history_content .projectile-frame-back .projectile-conent {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.history_content .projectile-frame-back .projectile-conent .title-ul {
  display: table;
  width: auto;
  text-align: center;
  clear: both;
  overflow: hidden;
  margin: 0 auto;
}

.history_content .projectile-frame-back .projectile-conent .title-ul li {
  float: left;
  width: auto;
  padding: 0 25px;
  text-align: center;
  background: #fff0eb;
  color: #f45540;
  margin-right: 20px;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.history_content .projectile-frame-back .projectile-conent .title-ul li.select {
  background: #ec6337;
  color: #fff;
}

.history_content .projectile-frame-back .projectile-conent .table-title {
  text-align: center;
  background: #f1f1f1;
  border: 1px solid #e7e7e7;
  height: 30px;
  line-height: 30px;
  margin-bottom: -1px;
}

.history_content .projectile-frame-back .projectile-conent .tow table,
.history_content .projectile-frame-back .projectile-conent .tow td,
.history_content .projectile-frame-back .projectile-conent .tow th {
  border: 1px solid #e7e7e7;
}

.history_content .projectile-frame-back .projectile-conent .tow ul {
  overflow: hidden;
}

.history_content .projectile-frame-back .projectile-conent .tow ul li {
  float: left;
  width: 40px;
}

.history_content .projectile-frame-back .projectile-conent .marginTop20 {
  margin-top: 20px;
}

.history_content .projectile-frame-back .projectile-conent .title-ul-one .one {
  margin-top: 20px;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .colorRed {
  color: #d22d34;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .colorLan {
  color: #217fbd;
  font-weight: bold;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .colorLin {
  color: #499530;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .tow
  table,
.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .tow
  td,
.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .tow
  th {
  border: 1px solid #e7e7e7;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .tow
  td,
.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .tow
  th {
  border-right: none;
  border-left: none;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-one
  .tow
  td.borderRight {
  border-right: 1px solid #e7e7e7 !important;
}

.history_content .projectile-frame-back .projectile-conent .title-ul-one table,
.history_content .projectile-frame-back .projectile-conent .title-ul-one td,
.history_content .projectile-frame-back .projectile-conent .title-ul-one th {
  border: 1px solid #e7e7e7;
}

.history_content .projectile-frame-back .projectile-conent .title-ul-one td,
.history_content .projectile-frame-back .projectile-conent .title-ul-one th {
  border-bottom: none;
  border-top: none;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.history_content .projectile-frame-back .projectile-conent .title-ul-tow tr {
  height: 35px;
  line-height: 35px;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-tow
  .tow
  li {
  width: 99.5px;
}

.history_content .projectile-frame-back .projectile-conent .title-ul-therr {
  width: 100%;
  overflow: hidden;
}

.history_content .projectile-frame-back .projectile-conent .title-ul-therr div {
  float: left;
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-therr
  div
  tr {
  height: 35px;
  line-height: 35px;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-therr
  div
  .tow
  li {
  width: 33.3333333%;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-therr
  div.title-div-therr
  .tow
  li {
  width: 25%;
}

.history_content
  .projectile-frame-back
  .projectile-conent
  .title-ul-therr
  div.title-div-tow {
  padding: 0;
}

.history_content .lhck160_ps_explanation {
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url("/images/question.png") no-repeat center;
  position: relative;
  margin-bottom: -3px;
}

.history_content .lhck160_ps_explanationBox {
  display: none;
  width: 250px;
  background: url("/images/lhck160_ps_explanationBox_top.png") no-repeat top;
  padding-top: 15px;
  font-size: 12px;
  color: #333;
  line-height: 22px;
  text-align: left;
  position: absolute;
  top: 18px;
  left: -98px;
  z-index: 999;
}

.history_content .lhck160_ps_explanationBox1 {
  display: block;
  width: 250px;
  background: url("/images/lhck160_ps_explanationBox_bottom.png") no-repeat
    bottom;
  padding-bottom: 10px;
}

.history_content .lhck160_ps_explanationBox2 {
  display: block;
  width: 223px;
  background: url("/images/lhck160_ps_explanationBox_middle.png") repeat-y;
  padding: 0px 15px 0px 12px;
}

.history_content .lhck160_ps_explanationBoxb {
  color: #ea5c38;
  font-weight: 100;
}

.history_content .explanation1:hover .explanation1_box {
  display: block;
}

.history_content .explanation2:hover .explanation2_box {
  display: block;
}
.history_content .btn {
  width: 68px;
  height: 28px;
  line-height: 14px;
  display: inline-block;
  font-weight: 400;
  color: #333;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #ccc;
  /*padding: .375rem .75rem;*/
  font-size: 1rem;
  /*line-height: 1.5;*/
  border-radius: 0.25rem;
  /*transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;*/
}
.history_content button {
  width: 68px;
  text-align: center;
  margin: 0px;
  border-radius: 3px;
  /*clear: none;*/
  cursor: pointer;
  height: 28px;
  line-height: 14px;
  border: 1px solid #ccc;
  color: #333;
}
.history_content .btn-outline-primary {
  color: #007bff;
  border-color: #ccc;
}
.history_content .row {
  margin-bottom: 5px;
}

@media (min-width: 576px) {
}

@media (max-width: 750px) {
  .history_content .history-code .choice-input {
    width: auto;
    /*max-width: 89%;*/
    margin-left: -40px;
    float: left;
    overflow: hidden;
    color: #676767;
  }

  .history_content .history-code .choice-input .choose_superior {
    width: 50px;
    text-align: center;
    margin: 0px;
    border-radius: 3px;
    clear: none;
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    border: 1px solid #ccc;
    color: #333;
  }

  .history_content .history-code .choice-input .choose_superior .icon_tick {
    background: url("/images/frame.png");
    margin-top: -5px;
    top: 1px;
  }

  .history_content .history-code .choice-input li {
    float: left;
    width: 50px;
    max-width: 50px;
    clear: none;
    margin-left: 1px;
    margin-right: 1px;
  }
  history-record table td:nth-child(0) {
    max-width: 40px;
  }
  history-record table td:nth-child(3) {
    max-width: 20px;
  }
}

@media (max-width: 992px) {
  .history_content .history-code .choice-input {
    width: auto;
    /*max-width: 89%;*/
    margin-left: -40px;
    float: left;
    overflow: hidden;
    color: #676767;
  }

  .history_content .history-code .choice-input .choose_superior {
    width: 50px;
    text-align: center;
    margin: 0px;
    border-radius: 3px;
    clear: none;
    cursor: pointer;
    height: 28px;
    line-height: 28px;
    border: 1px solid #ccc;
    color: #333;
  }

  .history_content .history-code .choice-input .choose_superior .icon_tick {
    background: url("/images/frame.png");
    margin-top: -5px;
    top: 1px;
  }

  .history_content .history-code .choice-input li {
    float: left;

    width: 50px;
    max-width: 50px;
    clear: none;
    margin-left: 1px;
    margin-right: 1px;
  }
}
</style>
