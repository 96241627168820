<template>
  <div class="fushi_content">
    <div class="fushi_header">
      <ul class="stat_menu">
        <li>
          <div class="name">{{ titles.fsyl }}：</div>
          <div class="cont">
            <a
              href="javascript:void(0)"
              v-for="item in titles.types"
              :key="item.id"
              :class="{ active: fushitype == item.id }"
              ><span @click="FuShiTypeClick(item.id)">{{ item.name }}</span></a
            >
          </div>
          <div class="clear"></div>
        </li>
      </ul>
      <div class="search_box">
        <div class="text_box">
          <span class="text">选择类型: </span>
          &nbsp;
          <div v-if="fushitype == 1" id="lianma">
            <template v-for="item in titles.types[0].subtype" :key="item.id">
              <input
                type="radio"
                name="lianma_type"
                value="item.id"
                :id="'r' + item.id"
                :checked="type == item.id"
                @click="TypeClick(item.id)"
              />
              <label @click="TypeClick(item.id)">{{ item.name }}</label>
            </template>
          </div>

          <div v-if="fushitype == 2" id="buzhong">
            <template v-for="item in titles.types[1].subtype" :key="item.id">
              <input
                type="radio"
                name="buzhong_type"
                value="1"
                :id="'r' + item.id"
                @click="TypeClick(item.id)"
                :checked="type == item.id"
              /><label :for="'r' + item.id" @click="TypeClick(item.id)">{{
                item.name
              }}</label>
            </template>
          </div>

          <div v-if="fushitype == 3" id="shengxiaolian">
            <template v-for="item in titles.types[2].subtype" :key="item.id">
              <input
                type="radio"
                name="buzhong_type"
                value="1"
                :id="'r' + item.id"
                @click="TypeClick(item.id)"
                :checked="type == item.id"
              /><label :for="'r' + item.id" @click="TypeClick(item.id)">{{
                item.name
              }}</label>
            </template>
          </div>

          <div v-if="fushitype == 4" id="weishulian">
            <template v-for="item in titles.types[3].subtype" :key="item.id">
              <input
                type="radio"
                name="buzhong_type"
                value="item.id"
                :id="'r' + item.id"
                @click="TypeClick(item.id)"
                :checked="type == item.id"
              /><label :for="'r' + item.id" @click="TypeClick(item.id)">{{
                item.name
              }}</label>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="table_block">
        <br />
        <table v-if="fushitype == 1 || fushitype == 2" class="hm_table">
          <thead>
            <tr>
              <th
                v-for="(item, index) in titles.wei"
                :key="index"
                :class="{ first_th: index == 0 }"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="first">
              <td class="first_td"></td>
              <td
                v-for="item in codes1"
                :key="item.id"
                @click="BallClick(item.id)"
              >
                <div class="item" :class="{ selects: item.selected }">
                  <div class="theBall" :class="item.color">{{ item.code }}</div>
                  <i class="icon_mask_tick"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, index) in codes2"
                :class="{ first_td: index == 0 }"
                :key="item.id"
                @click="BallClick(item.id)"
              >
                <div class="item" :class="{ selects: item.selected }">
                  <div class="theBall" :class="item.color">{{ item.code }}</div>
                  <i class="icon_mask_tick"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, index) in codes3"
                :class="{ first_td: index == 0 }"
                :key="item.id"
                @click="BallClick(item.id)"
              >
                <div class="item" :class="{ selects: item.selected }">
                  <div class="theBall" :class="item.color">{{ item.code }}</div>
                  <i class="icon_mask_tick"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, index) in codes4"
                :class="{ first_td: index == 0 }"
                :key="item.id"
                @click="BallClick(item.id)"
              >
                <div class="item" :class="{ selects: item.selected }">
                  <div class="theBall" :class="item.color">{{ item.code }}</div>
                  <i class="icon_mask_tick"></i>
                </div>
              </td>
            </tr>
            <tr>
              <td
                v-for="(item, index) in codes5"
                :class="{ first_td: index == 0 }"
                :key="item.id"
                @click="BallClick(item.id)"
              >
                <div class="item" :class="{ selects: item.selected }">
                  <div class="theBall" :class="item.color">{{ item.code }}</div>
                  <i class="icon_mask_tick"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table v-if="fushitype == 3" class="sx_table">
          <thead>
            <tr>
              <th class="first_th" colspan="12"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(item, index) in titles.shengxiao"
                :key="index"
                :class="{ first_td: index == 0 }"
                @click="BallClick(index)"
              >
                <div class="item" :class="{ selects: item.selected }">
                  <div class="sx_ball">{{ item.name }}</div>
                  <i class="sx_mask_tick"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table v-if="fushitype == 4" class="ws_table">
          <thead>
            <tr>
              <th class="first_th" colspan="12"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                v-for="(item, index) in titles.weishu"
                :key="index"
                :class="{ first_td: index == 0 }"
              >
                <div
                  class="item"
                  :class="{ selects: item.selected }"
                  @click="BallClick(index)"
                >
                  <div class="sx_ball">{{ item.name }}</div>
                  <i class="sx_mask_tick"></i>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="IsJisuan" class="row1">
          您选择了
          <span id="ballCount" class="text-primary">@selectcount</span>/49
          个号码，每注号码组成个数：
          <div class="search_box">
            <div class="choose choose_period">
              <div class="text_box">
                <span class="text">选择遗漏类型</span>
                <i class="icon icon_search_Triangle"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="row2">
          <button class="btn j_make_fushi" @click="JiSuan()">计算遗漏</button>
          <button class="btn_default2 j_clear" @click="Clear()">清空</button>
        </div>
      </div>
      <div id="tishi" class="tishi" v-html="tishicontent"></div>
    </div>
    <!-- <Message /> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      titles: {},
      fushitype: 1,
      type: 1,
      IsJisuan: false,
      tishicontent: "",
      codes1: [],
      codes2: [],
      codes3: [],
      codes4: [],
      codes5: [],
    };
  },
  methods: {
    getTitle: function() {
      let url = "/fushi/GetTitle";
      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;
          if (result.code == "1") {
            let title = this.Decrypt(result.list);
            this.titles = JSON.parse(title);
            this.codes1 = [];
            this.codes2 = [];
            this.codes3 = [];
            this.codes4 = [];
            this.codes5 = [];
            this.titles.codes.forEach((value) => {
              if (value.code >= "01" && value.code <= "09") {
                this.codes1.push(value);
              }
              if (value.code >= "10" && value.code <= "19") {
                this.codes2.push(value);
              }
              if (value.code >= "20" && value.code <= "29") {
                this.codes3.push(value);
              }
              if (value.code >= "30" && value.code <= "39") {
                this.codes4.push(value);
              }
              if (value.code >= "40" && value.code <= "49") {
                this.codes5.push(value);
              }
            });

            console.log(this.codes1);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    FuShiTypeClick(type) {
      this.fushitype = type;
      this.type = 1;
      this.Clear();
      console.log(this.titles.codes);
      console.log(this.codes1);
    },
    TypeClick(type) {
      this.type = type;
      this.Clear();
    },
    JiSuan() {
      let tshu = 0;
      switch (this.fushitype) {
        case 1:
          switch (this.type) {
            case 1:
              tshu = 2;
              break;
            case 2:
              tshu = 2;
              break;
            case 3:
              tshu = 2;
              break;
            case 4:
              tshu = 3;
              break;
            case 5:
              tshu = 3;
              break;
            case 6:
              tshu = 4;
              break;
          }
          break;
        case 2:
          switch (this.type) {
            case 1:
              tshu = 4;
              break;
            case 2:
              tshu = 5;
              break;
            case 3:
              tshu = 6;
              break;
            case 4:
              tshu = 7;
              break;
            case 5:
              tshu = 8;
              break;
            case 6:
              tshu = 9;
              break;
            case 7:
              tshu = 10;
              break;
            case 8:
              tshu = 11;
              break;
            case 9:
              tshu = 12;
              break;
          }
          break;
        case 3:
          switch (this.type) {
            case 1:
              tshu = 2;
              break;
            case 2:
              tshu = 3;
              break;
            case 3:
              tshu = 4;
              break;
            case 4:
              tshu = 5;
              break;
            case 5:
              tshu = 2;
              break;
            case 6:
              tshu = 3;
              break;
            case 7:
              tshu = 4;
              break;
            case 8:
              tshu = 5;
              break;
          }
          break;
        case 4:
          switch (this.type) {
            case 1:
              tshu = 2;
              break;
            case 2:
              tshu = 3;
              break;
            case 3:
              tshu = 4;
              break;
            case 4:
              tshu = 5;
              break;
            case 5:
              tshu = 2;
              break;
            case 6:
              tshu = 3;
              break;
            case 7:
              tshu = 4;
              break;
            case 8:
              tshu = 5;
              break;
          }
          break;
      }

      let SelectBalls = [];
      switch (this.fushitype) {
        case 1:
        case 2:
          for (let i = 0; i < 49; i++) {
            if (this.titles.codes[i].selected) {
              SelectBalls.push(this.titles.codes[i].code);
            }
          }
          break;
        case 3:
          for (let i = 0; i < 12; i++) {
            if (this.titles.shengxiao[i].selected) {
              SelectBalls.push(this.titles.shengxiao[i].name);
            }
          }
          break;
        case 4:
          for (let i = 0; i < 10; i++) {
            if (this.titles.weishu[i].selected) {
              SelectBalls.push(this.titles.weishu[i].name);
            }
          }
          break;
      }
      let ssum = SelectBalls.length;
      if (ssum < tshu) {
        alert("选择的数量不能少于" + tshu);
        return;
      }

      let sum = Math.round(
        (this.factorial(ssum) * 1.0) /
          this.factorial(ssum - tshu) /
          this.factorial(tshu),
        0
      );
      if (sum > 2000) {
        this.tishicontent =
          "<div class='result clearfix'><p class='tips'><i class='icon_warn'></i>抱歉！组合超过2000注，暂不提供详细数据，请您重新选择！</p></div></div>";
        return;
      }
      let data = SelectBalls.join("-");
      let title = SelectBalls.join(",");
      let url = "/fushi/post";
      let postdata = this.qs.stringify({
        tshu: tshu,
        fushitype: this.fushitype,
        type: this.type,
        title: title,
      });

      this.tishicontent =
        "<div style='margin:0 auto;text-aglin:center;'><span style='color:red;'>计算中,请稍后...</span></div>";
      this.axios
        .post(url, postdata)
        .then((res) => {
          console.log(res);
          let width = "15%";
          switch (this.fushitype) {
            case 1:
            case 4:
              switch (tshu) {
                case 2:
                  width = "30%";
                  break;
                default:
                  width = "30%";
                  break;
              }
              break;
            case 2:
              switch (tshu) {
                case 2:
                  width = "30%";
                  break;
                case 3:
                  width = "30%";
                  break;
                case 4:
                  width = "30%";
                  break;
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                  width = "30%";
                  break;
                default:
                  width = "40%";
                  break;
              }
              break;
            case 3:
              switch (tshu) {
                case 2:
                  width = "30%";
                  break;
                case 3:
                  width = "30%";
                  break;
                case 4:
                  width = "30%";
                  break;
                case 5:
                case 6:
                case 7:
                case 8:
                case 9:
                  width = "30%";
                  break;
              }
              break;
          }
          let results = JSON.parse(this.Decrypt(res.data));
          for (let i = 0; i < results.length; i++) {
            results[i] =
              "<div class='item j_item' style='width:" +
              width +
              ";text-align:left;margin-left:10px;' > " +
              results[i] +
              "</div >";
          }
          let name = "";
          switch (this.fushitype) {
            case 1:
            case 2:
              name = this.titles.hm;
              break;
            case 3:
              name = this.titles.sx;
              break;
            case 4:
              name = this.titles.ws;
              break;
          }
          data =
            "<div class='result_block'><div class='top'>你选择共<span class='text-primary'>" +
            ssum +
            "</span>个" +
            name +
            ",设置每组<span class='text-primary'>" +
            tshu +
            "</span>个" +
            name +
            ",共生成 <span class='text-primary'>" +
            results.length +
            "</span> " +
            this.titles.zhjg +
            "：</div>";
          data +=
            "<div class='result clearfix'>" +
            results.join(" ") +
            "</div></div>";
          this.tishicontent = data;
          console.log(this.tishicontent);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    factorial(n) {
      if (n == 0) {
        return 1;
      }
      let temp = this.factorial(n - 1) * n;
      return temp;
    },

    Clear() {
      for (let i = 0; i < this.titles.shengxiao.length; i++) {
        this.titles.shengxiao[i].selected = false;
      }
      for (let i = 0; i < this.titles.weishu.length; i++) {
        this.titles.weishu[i].selected = false;
      }
      for (let i = 0; i < this.titles.codes.length; i++) {
        this.titles.codes[i].selected = false;
      }
      this.tishicontent = "";
    },
    BallClick(index) {
      if (!this.titles.codes[index].selected) {
        let count = 0;
        switch (this.fushitype) {
          case 1:
          case 2:
            for (let i = 0; i < 49; i++) {
              if (this.titles.codes[i].selected) {
                count++;
              }
            }
            break;
          case 3:
            for (let i = 0; i < 12; i++) {
              if (this.titles.shengxiao[i].selected) {
                count++;
              }
            }
            break;
          case 4:
            for (let i = 0; i < 10; i++) {
              if (this.titles.weishu[i].selected) {
                count++;
              }
            }
            break;
        }

        switch (this.fushitype) {
          case 1:
            if (count >= 10) {
              alert("最多只能选择10个号码");
              return;
            }

            break;
          case 2:
            switch (this.type) {
              case 1:
              case 2:
                if (count >= 8) {
                  alert("最多可以选择8数码");
                  return;
                }
                break;
              case 3:
                if (count >= 9) {
                  alert("最多可以选择9数码");
                  return;
                }
                break;
              case 4:
              case 5:
                if (count >= 10) {
                  alert("最多可以选择10数码");
                  return;
                }
                break;
              case 6:
                if (count >= 11) {
                  alert("最多可以选择11数码");
                  return;
                }
                break;
              case 7:
                if (count >= 12) {
                  alert("最多可以选择12数码");
                  return;
                }
                break;
              case 8:
                if (count >= 13) {
                  alert("最多可以选择13数码");
                  return;
                }
                break;
              case 9:
                if (count >= 14) {
                  alert("最多可以选择14数码");
                  return;
                }
                break;
            }
            break;
          case 3:
          case 4:
            if (count >= 8) {
              alert("最多只能选择8个号码");
              return;
            }

            break;
        }
      }
      switch (this.fushitype) {
        case 1:
        case 2:
          this.titles.codes[index].selected = !this.titles.codes[index]
            .selected;

          break;
        case 3:
          this.titles.shengxiao[index].selected = !this.titles.shengxiao[index]
            .selected;
          break;
        case 4:
          this.titles.weishu[index].selected = !this.titles.weishu[index]
            .selected;
          break;
      }
    },
  },
  created: function() {
    this.getTitle();
  },

  mounted: function() {},
};
</script>

<style scoped>
.fushi_content {
  width: 100%;

  margin: 0 auto;
  display: block;
}

.fushi_content .stat_menu {
  padding: 0 20px;
}

.fushi_content .stat_menu li {
  width: 100%;
  line-height: 35px;
  overflow: hidden;
}

.fushi_content .stat_menu li .name {
  float: left;
  width: 80px;
  color: #2c2c2c;
  font-weight: bold;
  font-family: 微软雅黑;
  font-size: 14px;
  margin-top: 8px;
}

.fushi_content .stat_menu li .cont {
  float: left;
  text-align: left;
  margin-top: 8px;
  /*width: 1100px;*/
}

.fushi_content .stat_menu li .cont a {
  display: block;
  width: auto;
  float: left;
  height: 30px;
  line-height: 30px;
  position: relative;
  border-radius: 3px;
  border: 1px solid #efefef;
  padding: 0 10px;
  margin: 3px 15px 5px 0;
  background: #fdfdfd;
}

.fushi_content .stat_menu li .cont .active {
  background-color: #ea5c38 !important;
  color: #fff !important;
}

.fushi_content .stat_menu li .cont a:hover,
.statistics_code .stat_menu li .cont a.select {
  border: 1px solid #ea5c38;
  background: #ea5c38;
  color: #fff !important;
}

.fushi_content .stat_menu li .cont .cur {
  color: #ea5c38;
}

.fushi_content .stat_menu li .cont .hot_tubiao {
  background: url("/assets/images/shot.png") no-repeat;
  display: inline-block;
  height: 16px;
  width: 23px;
  position: absolute;
  top: -4px;
  right: -11px;
}

.fushi_content .stat_menu li .cont .new_tubiao {
  background: url("/assets/images/new.png") no-repeat;
  display: inline-block;
  height: 16px;
  width: 23px;
  position: absolute;
  top: -4px;
  right: -11px;
}

.fushi_content .disnone {
  display: none;
}

.fushi_content .posiRg {
  position: absolute;
  right: 20px;
  top: 0;
  color: #fff;
  height: 60px;
  line-height: 60px;
}

.fushi_content .posiRg .explain {
  background: url("../assets/images/red_qz.png") 0 21px no-repeat;
  padding-left: 20px;
  cursor: pointer;
  position: relative;
}

.fushi_content .posiRg .explain .canJan {
  top: -4px;
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  right: 19px;
}

.fushi_content .posiRg .explain .canJan2 {
  top: -5px;
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #e7e7e7 transparent;
  right: 19px;
}

.fushi_content .posiRg .explain .draw_content {
  position: absolute;
  margin-top: 30px;
  right: 0px;
  top: 30px;
  _right: 10px;
  _top: 133px;
  width: 395px;
  background: #fff;
  padding-top: 9px;
  background: #e7e7e7;
  z-index: 990;
  color: #000;
  text-align: left;
  z-index: 2;
  font-size: 14px;
}

.fushi_content .posiRg .explain .draw_content ol {
  padding: 17px 17px 8px 17px;
  border: 1px solid #e7e7e7;
  background: #fff;
}

.fushi_content .posiRg .explain .draw_content ol li {
  text-align: left;
  line-height: 20px;
  margin-bottom: 10px;
  color: #666;
}

.fushi_content :-moz-placeholder {
  color: #ccc;
}

.fushi_content ::-moz-placeholder {
  color: #ccc;
}

.fushi_content :-ms-input-placeholder {
  color: #ccc;
}

.fushi_content ::-webkit-input-placeholder {
  color: #ccc;
}

.fushi_content .icon_fushi_01,
.fushi_content .icon_fushi_02 {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

.fushi_content .icon_fushi_01 {
  position: relative;
  top: 4px;
  background: url("../assets/images/icon_fushi_01.png?v=uf9d6d5fb") no-repeat
    center;
}

.fushi_content .icon_fushi_02 {
  position: relative;
  top: 4px;
  background: url("../assets/images/icon_fushi_02.png?v=uf9d6d5fb") no-repeat
    center;
}

.fushi_content .search_box {
  margin: 0 10px;
}

.fushi_content .search_box .text_box {
  margin-top: 10px;
}

.fushi_content .search_box .text_box .text {
  display: block;
  float: left;
}

.fushi_content .search_box .text_box div {
  float: left;
  margin-top: -2px;
  margin-left: 10px;
}

.fushi_content .search_box .choose .text {
  background: #fdfdfd;
  border: 1px solid #cdcdcd;
  padding: 0 25px 0 10px;
  width: 65px;
  height: 28px;
  line-height: 28px;
  font-size: 16px;
  color: #333;
  text-align: left;
  margin-top: 10px;
}

.fushi_content .search_box .choose ul {
  top: 32px;
  z-index: 1;
}

.fushi_content .search_box .choose ul li {
  width: 80px;
  color: #333;
  text-align: left;
  padding: 0 10px;
  font-size: 14px;
}

.fushi_content .search_box .choose ul li:hover {
  color: #fff;
}

.fushi_content .search_box .choose .icon_search_Triangle {
  top: 14px;
}

.fushi_content .fushi_header {
  background: #fdfdfd;
  margin-top: 0px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  vertical-align: middle;
  color: #666666;
  font-size: 16px;
  position: relative;
}

.fushi_content .fushi_header .numberCount {
  display: inline-block;
  width: 80px;
  height: 28px;
  line-height: 28px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 0 10px;
  color: #333;
  margin: 0 30px 0 10px;
  font-size: 16px;
}

.fushi_content .fushi_header .chooseCount {
  height: 30px;
  line-height: 30px;
  width: 90px;
  font-size: 14px;
  margin-right: 20px;
}

.fushi_content .main {
  background: #fdfdfd;
  margin-top: 10px;
  padding: 0 20px 70px;
}

.fushi_content .main .main_header {
  height: 60px;
  line-height: 60px;
  color: #666666;
  font-size: 16px;
}

.fushi_content .main .main_header .btn_default {
  height: 30px;
  line-height: 28px;
  width: 80px;
  font-size: 14px;
  margin-left: 10px;
  border-radius: 4px;
  background: #fff0eb;
  color: #ec6337;
}

.fushi_content .main .main_header .btn_default.active {
  background: #ec6337;
  color: #fff;
}

.fushi_content .main table {
  width: 100%;
  text-align: center;
}

.fushi_content .main table thead tr {
  height: 40px;
  background: #f0f0f0;
}

.fushi_content .main table th {
  font-weight: normal;
  height: 40px;
  background: #f0f0f0;
  color: #333;
  font-size: 14px;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
}

.fushi_content .main table th.first_th {
  border-left: 1px solid #e5e5e5;
}

.fushi_content .main table td {
  padding: 5px 0;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.fushi_content .main table td.first_td {
  border-left: 1px solid #e5e5e5;
}

.fushi_content .main table .item {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 10px 10px;
}

.fushi_content .main table .item.selects .icon_mask_tick {
  display: inline-block;
  width: 44px;
  height: 45px;
  background: url("../assets/images/icon_mask_tick.png?v=uf9d6d5fb") no-repeat
    center;
  position: absolute;
  top: 1px;
  left: 10px;
}

.hm_table {
  margin-top: 30px;
  border-spacing: 1px;
}
.hm_table th {
  background: #fdfdfd;
}
.hm_table td {
  background: #fdfdfd;
}

.fushi_content .sx_table {
  margin-top: 30px;
}

.fushi_content .main .sx_table td {
  background: #fdfdfd;
}

.fushi_content .main .sx_table .item {
  position: relative;
}

.fushi_content .main .sx_table .item.selects .sx_mask_tick {
  display: inline-block;
  height: 60px;
  width: 64px;
  background: url("../assets/images/sx_mask_tick.png?v=uf9d6d5fb") no-repeat
    center;
  position: absolute;
  top: 8px;
  left: 10px;
}

.fushi_content .ws_table {
  margin-top: 30px;
}

.fushi_content .main .ws_table td {
  background: #fdfdfd;
}

.fushi_content .main .ws_table .item {
  position: relative;
}

.fushi_content .main .ws_table .item.selects .sx_mask_tick {
  display: inline-block;
  height: 60px;
  width: 64px;
  background: url("../assets/images/sx_mask_tick.png?v=uf9d6d5fb") no-repeat
    center;
  position: absolute;
  top: 8px;
  left: 10px;
}

.fushi_content .main .row1 {
  text-align: center;
  padding: 20px 0;
  color: #666;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  background: #f9f9f9;
}

.fushi_content .main .row2 {
  text-align: center;
  padding: 10px 0 30px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  background: #f9f9f9;
  height: 10px;
}

.fushi_content .main .row2 .btn,
.fushi_content .main .row2 .btn_default2 {
  height: 30px;
  line-height: 14px;
  border: 1px solid #ec6337;
  width: 90px;
  vertical-align: middle;
  margin-top: -4px;
  border-radius: 3px;
}
.fushi_content .main .tishi {
  margin-top: 0px;
  text-align: left;
}

.fushi_content .main .tishi >>> .result_block {
  border: 1px solid #e5e5e5;
  margin-top: 10px;
}

.fushi_content .main .tishi >>> .result_block .top {
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #666666;
  background: #f0f0f0;
}

.fushi_content .main .tishi >>> .result_block .result {
  padding: 0px 0;
}

.fushi_content .main .tishi >>> .result_block .result .item {
  width: 20%;
  display: inline-block;

  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  color: #666666;
}

.fushi_content .main .tishi >>> .result_block .tips {
  height: 55px;
  line-height: 55px;
  text-align: center;
  color: #cf633d;
}

.fbtj_dialog .content {
  padding: 0;
}

.fbtj_dialog .content .content_rig {
  padding: 0 15px;
  height: 35px;
  line-height: 35px;
  font-size: 0.8em;
  background: #fffae4;
  overflow: hidden;
  color: #696460;
}

.fbtj_dialog .content .content_rig img {
  margin-bottom: -4px;
}

.fbtj_dialog .content .conent {
  padding: 15px 20px;
}

.fbtj_dialog .content .conent .num {
  position: absolute;
  right: 10px;
  top: 10px;
}

.fbtj_dialog .content .conent .titleInput {
  width: 100%;
  background: #efefef;
  border: none;
  height: 35px;
  line-height: 35px\0;
  vertical-align: middle;
  max-width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.fbtj_dialog .content .conent .selects {
  overflow: hidden;
  height: 45px;
  line-height: 45px;
}

.fbtj_dialog .content .conent .selects ul {
  overflow: hidden;
}

.fbtj_dialog .content .conent .selects ul li {
  width: 60px;
  padding-left: 18px;
  box-sizing: border-box;
  float: left;
  background: url("/assets/images/sicon/icon_tick.png") 0 48% no-repeat;
  background-size: 14px 14px;
  cursor: pointer;
}

.fbtj_dialog .content .conent .selects ul .select {
  background: url("/assets/images/sicon/icon_tick_h.png") 0 47% no-repeat;
  background-size: 14px 14px;
}

.fbtj_dialog .content .conent .release_textarea {
  width: 100%;
  height: 235px;
  padding: 6px;
  box-sizing: border-box;
}
.j_clear {
  background-color: #e0e0e0;
  border-color: #e0e0e0 !important;
}
.btn {
  height: 34px;
  width: 120px;
  text-align: center;
  color: #fff;
  background: #ec6337;
  border-radius: 4px;
  line-height: 34px;
}
</style>
