import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import router from "./routes";
import axios from "axios";
import qs from "qs";
// import Decrypt from "./decrypt.js";
// import CryptoJs from "crypto-js";
// import AES from "crypto-js/aes";
import CryptoJS from "crypto-js";
/**************************************************************
 *字符串解密
 *   str：需要解密的字符串
 ****************************************************************/
function Decrypt(str) {
    var KEY = "12345678900000001234567890000000"; //32位
    var IV = "1234567890000000"; //16位
    var key = CryptoJS.enc.Utf8.parse(KEY);
    var iv = CryptoJS.enc.Utf8.parse(IV);
    var encryptedHexStr = CryptoJS.enc.Hex.parse(str);
    var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    var decrypt = CryptoJS.AES.decrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    console.log(decrypt);
    var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

// function Decrypt(str) {
//   // let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
//   // let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);//Utf8
//   const key = CryptoJS.enc.Utf8.parse("0000000671595991"); //十六位十六进制数作为秘钥
//   const iv = CryptoJS.enc.Utf8.parse("tdrdadq59tbss5n7"); //十六位十六进制数作为秘钥偏移量
//   let base64 = CryptoJS.enc.Base64.parse(str);
//   let src = CryptoJS.enc.Base64.stringify(base64);
//   let decrypt = CryptoJS.AES.decrypt(src, key, {
//     iv: iv,
//     mode: CryptoJS.mode.CBC,
//     padding: CryptoJS.pad.Pkcs7,
//   });
//   console.log(decrypt);
//   let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
//   //return decryptedStr.toString();
//   return decryptedStr;
// }
var app = createApp(App);
app.use(router);
//axios.defaults.baseURL = "/api";
//axios.defaults.headers
const http = axios.create({
    //baseURL: "http://api.6hbd.live/",
    baseURL: "https://api.am6h.cc/",
    //baseURL: "https://localhost:6001/",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //headers: { "Content-Type": "application/json" },
    //   headers: { "Access-Control-Allow-Origin": "*" },
});
// http.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

app.config.globalProperties.axios = http;
app.config.globalProperties.qs = qs;
app.config.globalProperties.Decrypt = Decrypt;

app.mount("#app");