<template>
  <div class="four">
    <div class="four-title" id="divPosition">
      <div class="title-name">选择显示：</div>
      <ul>
        <li v-for="(item, index) in titles" :key="index" @click="select(index)">
          <div class="clearBoth btn_div">
            <label :class="{ active: selects[index] }">
              <span>{{ item }}</span>
            </label>
          </div>
        </li>

        <li>
          <button class="clear_btn" id="clearPosition" @click="Clear()">
            还原
          </button>
        </li>
      </ul>
    </div>
    <div class="list">
      <template v-for="(item, index) in ss" :key="index">
        <table
          v-if="displays[index]"
          width="100%"
          border="1"
          align="center"
          cellpadding="0"
          cellspacing="0"
          class="j_roadmap_table j_roadmap_0"
        >
          <caption class="title caption">
            <div class="rg">
              {{ titles[index] }}&nbsp;<span class="colorChan">最新&darr;</span>
            </div>
          </caption>
          <tbody>
            <tr class="tdSpan" valign="top" v-html="item"></tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      displays: [true, true, true, true, true, true, true],
      selects: [false, false, false, false, false, false, false],
      list: [],
      titles: [],
      ss: [],
      type2: 1,
    };
  },
  methods: {
    select(index) {
      this.selects[index] = !this.selects[index];
      let count = 0;
      for (let i = 0; i < 7; i++) {
        if (this.selects[i]) {
          count++;
        }
      }
      if (count == 0) {
        for (let i = 0; i < 7; i++) {
          this.displays[i] = true;
        }
      } else {
        for (let i = 0; i < 7; i++) {
          this.displays[i] = this.selects[i];
        }
      }
    },
    Clear() {
      for (let i = 0; i < 7; i++) {
        this.selects[i] = false;
        this.displays[i] = true;
      }
    },
    getData() {
      let url = "/RoadMap/get?type=" + this.type;

      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;
          if (result.code == "1") {
            result = JSON.parse(this.Decrypt(result.data));

            this.list = JSON.parse(result.data);
            this.titles = result.titles;
            this.ss = [];
            for (let j = 0; j < 7; j++) {
              let rows = this.list[j];
              let s = "";
              let item = "";
              let count = 0;
              for (var m in rows) {
                let i = rows[m];
                if (item != i && i != "和") {
                  if (item != "") {
                    count++;
                    s += "</td>";
                  }
                  if (i == "单" || i == "大" || i == "禽" || i == "和") {
                    if (count % 2 == 0) {
                      s += "<td class='even '>";
                    } else {
                      s += "<td class='even backAsh'>";
                    }
                  } else {
                    if (count % 2 == 0) {
                      s += "<td class='odd'>";
                    } else {
                      s += "<td class='odd backAsh'>";
                    }
                  }
                  if (i != "和") {
                    item = i;
                  }
                }
                if (i == "和") {
                  s += " <span style='color:green'>" + i + "</span>";
                } else {
                  s += "<span>" + i + "</span>";
                }
              }

              this.ss.push(s + "");
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getData();
  },
  watch: {
    type() {
      this.getData(this.type);
    },
  },
};
</script>

<style scoped>
.caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: right;
  caption-side: top !important ;
}
.statistics_code .tableList .four .tableMargin {
  margin-bottom: 20px;
}

.statistics_code .tableList .four .four-title {
  overflow: hidden;
  margin-bottom: 10px;
  background: #f8f8f8;
  padding: 5px 0;
}

.statistics_code .tableList .four .four-title label {
  cursor: pointer;
}

.statistics_code .tableList .four .four-title .title-name {
  float: left;
  height: 30px;
  line-height: 30px;
  margin-top: 6px;
  padding-left: 20px;
}

.statistics_code .tableList .four .four-title ul {
  float: left;
  overflow: hidden;
  margin: 0px;
}

.statistics_code .tableList .four .four-title ul li {
  float: left;
  margin: 6px;
  clear: none;
}

.statistics_code .tableList .four .four-title ul li .btn_div label {
  display: inline-block;
  width: 68px;
  text-align: center;
  margin: 0px;
  border-radius: 3px;
  clear: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  color: #333;
}

.statistics_code .tableList .four .four-title ul li .btn_div label:hover {
  border-color: #ec6337;
  color: #ec6337;
}

.statistics_code .tableList .four .four-title ul li .btn_div label.active {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.statistics_code
  .tableList
  .four
  .four-title
  ul
  li
  .btn_div
  label.active:hover {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.statistics_code .tableList .four .four-title ul li input[type="checkbox"] {
  cursor: pointer;
  height: 0px;
  width: 0px;
  margin: 0px;
  display: none;
}

.statistics_code .tableList .four .four-title ul li .botrg {
  margin-top: 5px;
  width: 1px;
  height: 20px;
  background-color: #bbb;
}

.statistics_code .tableList .four .four-title ul li button {
  float: left;
  padding: 0 10px;
  height: 24px;
  line-height: normal;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 0.8em;
  margin-top: 3px;
  background: none;
  color: #686868;
}

.statistics_code .tableList .four .four-title ul li .clear_btn {
  cursor: pointer;
  background: #41b45f;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  width: 70px;
  border: none;
  margin-top: 0px;
}
.statistics_code .tableList .four .list >>> table,
.statistics_code .tableList .four .list >>> td,
.statistics_code .tableList .four .list >>> th {
  border: 1px solid #ccc;
}
.statistics_code .tableList .four .list .tdSpan >>> td span {
  display: block;
  width: 100%;
  text-align: center;
}
.statistics_code .tableList .four .list >>> .odd {
  color: blue !important;
}
.statistics_code .tableList .four .list >>> table,
.statistics_code .tableList .four .list >>> td,
.statistics_code .tableList .four .list >>> th {
  border: 1px solid #ccc;
}
.statistics_code .tableList .four .list >>> .even {
  color: red !important;
}
.statistics_code .tableList .four .list >>> table {
  margin-bottom: 10px;
}
.statistics_code .tableList .four .list >>> .title {
  border: 1px solid #ccc;
  background: #f0f0f0;
  font-size: 0.8em;
  border-bottom: none;
}

.statistics_code .tableList .four .four-title ul li .clear_btn:hover {
  background: #30a04d;
}
</style>
