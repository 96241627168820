<template>
  <div class="page-wrap" v-html="content"></div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    getData: function() {
      let url = "/HistorySevice/GetLaw";
      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;

          if (result.code == "1") {
            this.content = this.Decrypt(result.list);
            console.log(this.content);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getData();
  },
};
</script>

<style>
.page-wrap {
  width: 100%;
  min-height: 100%;
  text-align: left;
}
.about_content .about_block {
  background: #fdfdfd;
  margin: 20px 0;
  padding: 0 20px;
  padding-bottom: 35px;
}

.about_content .about_block h1 {
  height: 70px;
  line-height: 70px;
  color: #ce6647;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #f7f7f7;
  font-weight: normal;
}

.about_content .about_block p {
  color: #333333;
  line-height: 25px;
  text-indent: 2em;
}
.law_content .law_block p {
  text-indent: 0;
}
</style>
