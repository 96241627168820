<template>
  <div class="page-wrap" v-html="content"></div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    getData: function() {
      let url = "/HistorySevice/GetRules";
      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;

          if (result.code == "1") {
            this.content = this.Decrypt(result.list);
            console.log(this.content);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getData();
  },
};
</script>

<style scoped>
.page-wrap {
  width: 100%;
  min-height: 100%;
  text-align: left;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
table {
  border-collapse: collapse;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
}

.page-wrap >>> .liveTelecast_content {
  width: 100%;
  /*min-width: 1200px;*/
  margin: 0 auto;
  display: block;
}

.page-wrap >>> .liveTelecast_content .hot-drawing {
  margin-bottom: 0px;
}

.page-wrap >>> .liveTelecast_content .liveTelecast_code {
  margin-top: 0px;
  overflow: hidden;
  margin-bottom: 15px;
}

.page-wrap >>> .liveTelecast_content .liveTelecast_code .liveTelecast_code-lt {
  float: left;
  background: #fdfdfd;
  width: 640px;
  padding: 0 15px 0 20px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  h2 {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video {
  width: 100%;
  height: 360px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video
  .no_h5 {
  height: 100%;
  width: 100%;
  border: 1px solid #c6c6c6;
  text-align: center;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video
  .no_h5
  img {
  margin: 120px auto 15px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video
  .no_h5
  p {
  color: #666666;
  font-size: 16px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .replicating {
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  padding: 22px 0 15px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .replicating
  input.input-text {
  display: block;
  float: left;
  width: 380px;
  padding: 0 10px;
  height: 33px;
  line-height: 35px\0;
  /* vertical-align: middle; */
  border: 1px solid #ccc;
  background: none;
  border-radius: 2px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .replicating
  button {
  float: left;
  width: 110px;
  height: 35px;
  line-height: normal;
  border-radius: 2px;
  margin-left: 9px;
}

.page-wrap >>> .liveTelecast_content .liveTelecast_code .liveTelecast_code-rg {
  float: right;
  width: 480px;
  padding: 0 15px;
  background: #fdfdfd;
  height: 475px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eee;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title
  .rg-title-left {
  line-height: 50px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title
  .year {
  position: absolute;
  right: 6px;
  top: 8px;
  text-align: center;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title
  .year.search_box2
  .choose
  .text {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-coneent {
  width: 100%;
  height: 400px;
  margin: 10px 0;
  overflow: auto;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-ul
  li {
  margin: 0 10px 15px 0;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-coneent::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  background: #f2f2f2;
}

.page-wrap
  >>> .liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-coneent::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

.page-wrap >>> .hot-drawing {
  width: 100%;
  background-color: #fdfdfd;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0px 0 15px;
}

.page-wrap >>> .hot-drawing .hot-drawing-title {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #efefef;
  overflow: hidden;
  height: 40px;
  line-height: 40px;
}

.page-wrap >>> .hot-drawing .hot-drawing-title .name {
  float: left;
  font-size: 1.1em;
}

.page-wrap >>> .hot-drawing .hot-drawing-title ul {
  float: right;
}

.page-wrap >>> .hot-drawing .hot-drawing-title ul li {
  float: left;
  margin-left: 13px;
}

.page-wrap >>> .hot-drawing .hot-drawing-title ul li a {
  color: #f76631;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent {
  padding: 15px 0;
  text-align: center;
  overflow: hidden;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li {
  margin-right: 14.5px;
  margin-right: 13px\9;
  float: left;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li img {
  width: 132px;
  background: #dfdcde;
  border: 1px solid #dfdcde;
  box-sizing: border-box;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li p {
  width: 100%;
  background: none;
  height: 30px;
  line-height: 30px;
  margin-top: -3px;
  font-size: 16px;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li.onMargin {
  margin: 0;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li:hover img {
  width: 132px;
  border: 1px solid #ec6337;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li:hover p {
  width: 100%;
  background-color: #ec6337;
  color: #fff;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li.select img {
  width: 132px;
  border: 1px solid #ec6337;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li.select p {
  width: 100%;
  background-color: #ec6337;
  color: #fff;
}

.page-wrap >>> .hot-drawing .hot-drawing-conent li:nth-last-child(1) {
  margin-right: 0;
}
.page-wrap >>> .one table td:nth-child(1) {
  padding-left: 10px;
}
</style>
