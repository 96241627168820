import { createRouter, createWebHistory } from "vue-router";
import Historys from "./components/historys.vue";
import ShiPin from "./components/shipin.vue";
import Rules from "./components/rules.vue";
import Statistics from "./components/statistics.vue";
import FuShi from "./components/fushi.vue";
import About from "./components/about.vue";
import Law from "./components/law.vue";
import Disclaimer from "./components/disclaimer.vue";
var router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "", component: Historys },
    { path: "/historys", component: Historys },
    { path: "/shipin", component: ShiPin },
    { path: "/statistics", component: Statistics },
    { path: "/fushi", component: FuShi },
    { path: "/rules", component: Rules },
    { path: "/about", component: About },
    { path: "/law", component: Law },
    { path: "/disclaimer", component: Disclaimer },
  ],
});
export default router;
