<template>
  <div class="page-wrap" v-html="content"></div>
</template>
<script>
export default {
  data() {
    return {
      content: "",
    };
  },
  methods: {
    getData: function() {
      let url = "/HistorySevice/GetAbout";
      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;

          if (result.code == "1") {
            this.content = this.Decrypt(result.list);
            console.log(this.content);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getData();
  },
};
</script>

<style></style>
