<template>
  <div class="list">
    <div class="five">
      <div class="conent">
        <div class="content-one">
          <h3>{{ nextdraw }}</h3>
        </div>
      </div>
    </div>
    <div class="history_content stat_history_content">
      <div class="history-code" style="padding: initial">
        <div class="history-record">
          <table
            width="100%"
            border="1"
            align="center"
            cellpadding="0"
            cellspacing="0"
          >
            <tbody>
              <tr align="center" class="table-header">
                <td width="166">{{ titles.qsh }}</td>
                <td width="500">{{ titles.zm }}</td>
                <td width="98">{{ titles.tm }}</td>
                <td width="98">{{ titles.tmdsh }}</td>
                <td width="98">{{ titles.tmdx }}</td>
                <td width="98">{{ titles.zhdsh }}</td>
                <td width="98">{{ titles.zhdx }}</td>
              </tr>
              <tr
                v-for="(item, index) in list"
                :key="index"
                :class="{ backAsh: index % 2 != 0 }"
              >
                <td>
                  <span class="colorChen">{{ item.draw }}</span
                  >&nbsp;{{ item.time }}
                </td>
                <td>
                  <ul class="theBall-ul">
                    <li v-for="(item2, index2) in item.zm" :key="index2">
                      <div class="theBall-frame">
                        <div class="theBall" :class="item2.color">
                          {{ item2.code }}
                        </div>

                        <span class="text-botton">{{ item2.shengxiao }}</span>
                      </div>
                    </li>
                  </ul>
                </td>
                <td class="numbre">
                  <div class="theBall-frame">
                    <div class="theBall" :class="item.tm.color">
                      {{ item.tm.code }}
                    </div>
                    <span class="text-botton">{{ item.tm.sx }}</span>
                  </div>
                </td>

                <td>
                  <span :class="{ colorChen: item.tmdsh == titles.odd }">{{
                    item.tmdsh
                  }}</span>
                </td>
                <td>
                  <span :class="{ colorChen: item.tmdx == titles.big }">{{
                    item.tmdx
                  }}</span>
                </td>
                <td>
                  <span :class="{ colorChen: item.zhdsh == titles.odd }">{{
                    item.zhdsh + "(" + item.sum + ")"
                  }}</span>
                </td>
                <td>
                  <span :class="{ colorChen: item.zhdx == titles.big }">{{
                    item.zhdx
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nextdraw: "",
      titles: {},
      list: [],
    };
  },
  methods: {
    getData() {
      let url = "/Historyperiod/Get";
      this.axios
        .get(url)
        .then((res) => {
          console.log(res);
          let result = res.data;
          if (result.code == "1") {
            result = JSON.parse(this.Decrypt(result.data));
            this.list = result.list;
            this.titles = result.titles;
            this.nextdraw = result.nextdraw;
            console.log(this.titles);
            console.log(this.list);
            console.log(this.nextdraw);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getData();
  },
};
</script>

<style scoped>
.history_content .history-code .history-record {
  width: 100%;
  margin-top: 10px;
}

.history_content .history-code .history-record table,
.history_content .history-code .history-record td,
.history_content .history-code .history-record th {
  border: 1px solid #ccc;
}

.history_content .history-code .history-record td,
.history_content .history-code .history-record th {
  border-bottom: none;
  border-top: none;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 5px 0;
}

.history_content .history-code .history-record .table-header {
  background: #e0e0e0;
  border: 1px solid #ccc;
}

.history_content .history-code .history-record .theBall-ul {
  width: auto;
  margin: 0 auto;
  display: table;
}

.history_content .history-code .history-record .theBall-ul li {
  float: left;
  margin: 3px 3px;
}

.history_content .history-code .history-record .history-attribute {
  margin-top: 20px;
}

.history_content .history-code .history-record .history-attribute table,
.history_content .history-code .history-record .history-attribute td,
.history_content .history-code .history-record .history-attribute th {
  border: 1px solid #ccc;
}
.history_content .history-code .colorChen {
  color: #ee6d58;
}
</style>
