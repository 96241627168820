<template>
  <div class="container">
    <div class="header">
      <div class="logo">
        <a href="/"><img id="logo" src="./assets/images/logo.png" alt=""/></a>
      </div>
      <div class="menu">
        <ul>
          <li>
            <router-link to="/historys" @click="menuclick(1)">{{
              titles.kjjl
            }}</router-link>
          </li>
          <li>
            <router-link to="/shipin" @click="menuclick(2)">{{
              titles.kjzb
            }}</router-link>
          </li>
          <li>
            <router-link to="/statistics" @click="menuclick(3)">{{
              titles.tbtj
            }}</router-link>
          </li>
          <li>
            <router-link to="/fushi" @click="menuclick(4)">{{
              titles.fsyl
            }}</router-link>
          </li>
          <li>
            <router-link to="/rules" @click="menuclick(5)">{{
              titles.wfgz
            }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="maincontainer">
      <router-view></router-view>
    </div>

    <div class="footer">
      <div>
        <router-link to="/disclaimer">{{ titles.mzsm }}</router-link>
        <router-link to="/law">{{ titles.flsm }}</router-link>
        <router-link to="/about">{{ titles.lxwm }}</router-link>
      </div>
      <div class="copyright">
        <p>
          {{ titles.jg }}
        </p>
        <p>{{ titles.bq }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      curindex: 1,
      titles: [],
    };
  },

  methods: {
    menuclick: function(index) {
      this.curindex = index;
    },
    getTitle: function() {
      let url = "/HistorySevice/GetAppTitle";
      this.axios
        .get(url)
        .then((res) => {
          let result = res.data;

          if (result.code == "1") {
            let title = this.Decrypt(result.list);

            this.titles = JSON.parse(title);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getTitle();
  },
};
</script>
<style>
* {
  font-family: Arial, MicrosoftYaHei, "微软雅黑";
  list-style: none;
}
body {
  text-align: left;
  background-color: #fff;
}
.container {
  display: flex;
  flex-direction: column;
  align-content: space-between;

  /* width: 1200px; */
  margin: 0 auto;
  padding: 0px;
  background: #fdfdfd;
  max-width: 1200px;
  min-width: 750px;
  min-height: 100%;
  height: 100%;
}

.header {
  height: 65px;
  width: 100%;
  display: flex;
  border-bottom: 2px solid #ce6647;
}
.header .logo {
  width: 200px;
}
.header .logo #logo {
  width: 198px;
  height: 62px;
}

.header .menu {
  flex: 1;
}
.header .menu ul {
  margin-top: -20px;
  margin-left: 10px;
}

.header .menu ul li {
  float: left;
  display: block;
  padding: 0 10px;

  text-align: center;
  min-width: 60px;
  line-height: 77px;
  font-size: 16px;
  margin-top: 15px;
}

.header .menu ul li a {
  text-decoration: none;
  /* color: #212121; */
  outline: none;
  cursor: pointer;
}
.header .menu ul li a:hover {
  color: #ce6647;
}
.header .menu ul li a.active {
  color: #ce6647;
  /* font-weight: bold;*/
}
.router-link-active {
  color: #ce6647;
  /* font-weight: bold;*/
}
.maincontainer {
  flex: 1;
}

.footer {
  height: 109px;
  width: 100%;
  color: #9d9d9d;
  padding: 0 0;
  /* margin-left: -15px; */
}
.footer a {
  color: #9d9d9d;
  padding: 0 20px;
}
.footer a:hover {
  color: #ef642d;
}
.footer a.active {
  color: #ef642d;
  padding: 0 20px;
}
.footer .copyright {
  padding-top: 10px;
  font-size: 12px;
}

.theBall {
  display: inline-block;
  text-align: center;
  width: 28px;
  height: 36px;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  padding: 0px 3px 0 5px;
  background-image: url("./assets/images/go6h_ballBg.png");
  background-repeat: no-repeat;
  background-size: 144px;
}

.backAsh {
  background: #eeeeee;
}
.theBall_blue {
  background-position: 0 0;
}

.theBall_red {
  background-position: -36px 0;
}

.theBall_green {
  background-position: -72px 0;
}

.sx_ball {
  display: inline-block;
  width: 58px;
  height: 58px;
  background: url("./assets/images/sx_ball.png?v=uf9d6d5fb") no-repeat center;
  text-align: center;
  line-height: 48px;
  text-indent: 4px;
  font-size: 20px;
}
.colorChan {
  color: #eb6338 !important;
}
.statistics_code {
  background: #fdfdfd;
  overflow: hidden;
  padding: 20px 0px;
}
.rules_content .rules_block {
  background: #fdfdfd;
  margin: 20px 0;
  padding: 0 20px;
  padding-bottom: 35px;
}
.rules_content .rules_block h1 {
  height: 70px;
  line-height: 70px;
  color: #ce6647;
  font-size: 24px;
  text-align: center;
  border-bottom: 1px solid #f7f7f7;
  font-weight: normal;
}
.rules_content .rules_block .title {
  color: #d22d34;
  font-weight: bold;
  font-size: 16px;
  line-height: 28px;
}
.rules_content .rules_block h2 {
  display: block;
  color: #217fbd;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
}
.rules_content .rules_block .h3 {
  color: #217fbd;
  font-size: 14px;
  font-weight: bold;
}
.rules_content .colorLin {
  color: #499530 !important;
  font-weight: bold;
}
.rules_content .colorRed {
  color: #d22d34 !important;
  font-weight: bold;
}
.rules_content .colorLan {
  color: #019bf7 !important;
  font-weight: bold;
}
.rules_content .td_header {
  background-color: #a2c2ef;
  font-weight: bold;
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.mail {
  color: royalblue;
  text-decoration-line: underline;
}
.text-primary {
  color: #d22d34;
}
[v-cloak] {
  display: none;
}
</style>
