<template v-cloak>
  <div class="page-wrap">
    <div class="liveTelecast_content">
      <!--广告-->
      <div class="advertisement_frame">
        <ul class="Advertisement" id="ad_stat_top" style="display: none"></ul>
      </div>
      <a
        name="chart"
        style="width: 0px; height: 0px; margin: 0px; padding: 0px"
      ></a>
      <div class="statistics_code">
        <ul class="stat_menu">
          <li>
            <div class="name">{{ titles.tm }}：</div>
            <div class="cont">
              <a
                v-for="(item, index) in titles.tms"
                :key="index"
                href="javascript:void(0)"
                :id="'tema_' + item.type + '_' + item.subtype"
                :title="item.title"
                :class="{ active: curindex == item.id }"
                @click="
                  TongJi(
                    item.type,
                    item.subtype,
                    item.count,
                    item.sort,
                    item.zhengma,
                    item.id,
                    item.name
                  )
                "
                ><span> {{ item.name }}</span></a
              >
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <div class="name">{{ titles.zm }}：</div>
            <div class="cont">
              <a
                v-for="(item, index) in titles.zms"
                :key="index"
                href="javascript:void(0)"
                :id="'tema_' + item.type + '_' + item.subtype"
                :title="item.title"
                :class="{ active: curindex == item.id }"
                @click.prevent="
                  TongJi(
                    item.type,
                    item.subtype,
                    item.count,
                    item.sort,
                    item.zhengma,
                    item.id,
                    item.name
                  )
                "
                ><span> {{ item.name }}</span></a
              >
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <div class="name">{{ titles.sx }}：</div>
            <div class="cont">
              <a
                v-for="(item, index) in titles.sxs"
                :key="index"
                href="javascript:void(0)"
                :id="'tema_' + item.type + '_' + item.subtype"
                :title="item.title"
                :class="{ active: curindex == item.id }"
                @click.prevent="
                  TongJi(
                    item.type,
                    item.subtype,
                    item.count,
                    item.sort,
                    item.zhengma,
                    item.id,
                    item.name
                  )
                "
                ><span> {{ item.name }}</span></a
              >
            </div>
            <div class="clear"></div>
          </li>

          <li>
            <div class="name">{{ titles.lz }}：</div>
            <div class="cont">
              <a
                v-for="(item, index) in titles.lzs"
                :key="index"
                href="javascript:void(0)"
                @click.prevent="TypeClick(item.type, item.subtype, item.id)"
                :id="'tema_' + item.type + '_' + item.subtype"
                :title="item.title"
                :class="{ active: curindex == item.id }"
                ><span> {{ item.name }}</span></a
              >
            </div>
            <div class="clear"></div>
          </li>
          <li>
            <div class="name">{{ titles.qt }}：</div>
            <div class="cont">
              <a
                v-for="(item, index) in titles.qts"
                :key="index"
                href="javascript:void(0)"
                :id="'tema_' + item.type + '_' + item.subtype"
                :title="item.title"
                :class="{ active: curindex == item.id }"
                @click.prevent="TypeClick(item.type, item.subtype, item.id)"
                ><span> {{ item.name }}</span></a
              >
            </div>
            <div class="clear"></div>
          </li>
        </ul>

        <div class="tableList">
          <div v-if="type < 4" class="one">
            <div class="title row">
              <div class="draw" v-if="isdrawdisplay">
                <span class="fl">期数:&nbsp;&nbsp;</span>

                <input
                  type="radio"
                  value="100"
                  name="stat_period"
                  id="stat_period1"
                  v-model="count"
                  @click="DrawClick(100)"
                /><label @click="DrawClick(100)" for="stat_period1">100</label>
                <input
                  type="radio"
                  value="200"
                  name="stat_period"
                  id="stat_period2"
                  v-model="count"
                  @click="DrawClick(200)"
                /><label @click="DrawClick(200)" for="stat_period2">200</label>
                <input
                  type="radio"
                  value="300"
                  name="stat_period"
                  id="stat_period3"
                  v-model="count"
                  @click="DrawClick(300)"
                /><label @click="DrawClick(300)" for="stat_period3">300</label>
                <input
                  type="radio"
                  value="400"
                  name="stat_period"
                  id="stat_period4"
                  v-model="count"
                  @click="DrawClick(400)"
                /><label @click="DrawClick(400)" for="stat_period4">400</label>
                <input
                  type="radio"
                  value="500"
                  name="stat_period"
                  id="stat_period5"
                  v-model="count"
                  @click="DrawClick(500)"
                /><label @click="DrawClick(500)" for="stat_period5">500</label>
                <input
                  type="radio"
                  value="10000"
                  name="stat_period"
                  id="stat_period6"
                  v-model="count"
                  @click="DrawClick(10000)"
                /><label @click="DrawClick(10000)" for="stat_period6"
                  >全部</label
                >
              </div>

              <div class="zhengma" v-if="iszhengmadisplay">
                <ul class="rg">
                  <li
                    @click="ZhengMaClick(0)"
                    :class="{ colorChan: zhengma == 0 }"
                  >
                    {{ titles.zm1 }}
                  </li>
                  <li
                    @click="ZhengMaClick(1)"
                    :class="{ colorChan: zhengma == 1 }"
                  >
                    {{ titles.zm2 }}
                  </li>
                  <li
                    @click="ZhengMaClick(2)"
                    :class="{ colorChan: zhengma == 2 }"
                  >
                    {{ titles.zm3 }}
                  </li>
                  <li
                    @click="ZhengMaClick(3)"
                    :class="{ colorChan: zhengma == 3 }"
                  >
                    {{ titles.zm4 }}
                  </li>
                  <li
                    @click="ZhengMaClick(4)"
                    :class="{ colorChan: zhengma == 4 }"
                  >
                    {{ titles.zm5 }}
                  </li>
                  <li
                    @click="ZhengMaClick(5)"
                    :class="{ colorChan: zhengma == 5 }"
                  >
                    {{ titles.zm6 }}
                  </li>
                </ul>
              </div>

              <div class="sort" v-if="issortdisplay">
                <span>排序:</span>

                <input
                  type="radio"
                  value="1"
                  name="stat_sort"
                  id="stat_sort1"
                  @click="SortClick(1)"
                  v-model="sort"
                /><label for="stat_sort1">{{ titles.ahm }}</label>
                <input
                  type="radio"
                  value="2"
                  name="stat_sort"
                  id="stat_sort2"
                  @click="SortClick(2)"
                /><label for="stat_sort2">{{ titles.arm }}</label>
              </div>
            </div>

            <div class="title-conent">
              <div
                class="conent"
                id="chartcontainer"
                style="width: 100%; height: 448px"
              ></div>
              <div v-if="istishi">正在加载中...</div>
            </div>
          </div>

          <template v-if="type == 4">
            <RoadMap :type="subtype" />
          </template>

          <div v-if="type == 5 && subtype == 1">
            <Historyperiod />
          </div>
          <div v-show="type == 5 && subtype == 2">
            <Shengxiaodistribut />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HighCharts from "highcharts";
import Historyperiod from "./Historyperiod.vue";
import Shengxiaodistribut from "./Shengxiaodistribut.vue";
import RoadMap from "./RoadMap.vue";
export default {
  components: {
    Historyperiod,
    Shengxiaodistribut,
    RoadMap,
  },
  data() {
    return {
      titles: {},
      title: "",
      curindex: 0,
      type: 1,
      subtype: 1,
      count: 300,
      zhengma: 0,
      issortdisplay: false,
      iszhengmadisplay: false,
      isdrawdisplay: false,
      istishi: false,
      chartOptions: {},
      chartColor: [
        "#4572A7",
        "#AA4643",
        "#89A54E",
        "#80699B",
        "#3D96AE",
        "#DB843D",
        "#92A8CD",
        "#A47D7C",
        "#B5CA92",
      ],
    };
  },

  methods: {
    getTitle: function() {
      let url = "/tongji/GetTitle";
      this.axios
        .get(url)
        .then((res) => {
          console.log(res);
          let result = res.data;
          if (result.code == "1") {
            this.titles = JSON.parse(this.Decrypt(result.list));
            console.log(this.titles);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    TongJi(type, subtype, count, sort, zhengma, id, title) {
      this.curindex = id;
      this.type = type;
      this.subtype = subtype;
      this.IsDisplay(type, subtype);
      this.sort = sort;
      this.count = count;
      this.zhengma = zhengma;
      this.istishi = true;
      this.title = title;
      if (this.type == 2) {
        let temp = "";
        switch (this.zhengma) {
          case 0:
            temp = this.titles.zm1;
            break;
          case 1:
            temp = this.titles.zm2;
            break;
          case 2:
            temp = this.titles.zm3;
            break;
          case 3:
            temp = this.titles.zm4;
            break;
          case 4:
            temp = this.titles.zm5;
            break;
          case 5:
            temp = this.titles.zm6;
            break;
        }
        switch (this.subtype) {
          case 3:
            this.title = temp + this.titles.tzs;
            break;
          case 9:
            this.title = temp + this.titles.mlmyl;
            break;
          case 10:
            this.title = temp + this.titles.mbsyl;
            break;
          case 11:
            this.title = temp + this.titles.trm;
            break;
          case 12:
            this.title = temp + this.titles.tyl;
            break;
        }
      }
      if (type == 5) {
        return;
      }

      this.axios
        .get(
          "/TongJi/get?type=" +
            type +
            "&xiangmu=" +
            subtype +
            "&count=" +
            count +
            "&sort=" +
            sort +
            "&zhengma=" +
            zhengma
        )
        .then((res) => {
          let data = JSON.parse(this.Decrypt(res.data));

          switch (this.type) {
            case 1:
              switch (this.subtype) {
                case 1:
                  try {
                    this.simpleChart(this.title, data, this.type, this.zhengma);
                  } catch (e) {
                    console.log(e);
                  }

                  break;

                case 2:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 3:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 4:
                  this.simpleChart(this.title, data, this.type, this.zhengma);
                  break;

                case 5:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 7:
                  this.showChartline(
                    this.title,
                    data,
                    this.type,
                    this.zhengma,
                    1,
                    49
                  );
                  break;
                case 10:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 11:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
              }
              break;
            case 2:
              switch (this.subtype) {
                case 1:
                  this.simpleChart(this.title, data, this.type, this.zhengma);
                  break;
                case 11:
                  this.simpleChart(this.title, data, this.type, this.zhengma);
                  break;
                case 2:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 3:
                  this.showChartline(
                    this.title,
                    data,
                    this.type,
                    this.zhengma,
                    1,
                    49
                  );
                  break;
                case 12:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 9:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;

                case 10:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
              }
              break;
            case 3:
              switch (this.subtype) {
                case 1:
                  this.simpleChart(this.title, data, this.type, this.zhengma);
                  break;
                case 2:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
                case 3:
                  this.simpleChart(this.title, data, this.type, this.zhengma);
                  break;
                case 4:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;

                case 5:
                  this.groupChart(this.title, data, this.type, this.zhengma);
                  break;
              }
              break;
          }
          this.istishi = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    TypeClick(type, subtype, id) {
      this.curindex = id;
      this.type = type;

      this.subtype = subtype;
      console.log(this.type);
      console.log(this.subtype);

      this.IsDisplay(type, subtype);
    },
    IsDisplay(type, xiangmu) {
      switch (type) {
        case 1:
          switch (xiangmu) {
            case 1:
              this.isdrawdisplay = true;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;
            case 2:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;
            case 3:
            case 5:
            case 7:
            case 10:
            case 11:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = false;
              this.issortdisplay = false;
              break;
            case 4:
              this.isdrawdisplay = true;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;
          }
          break;
        case 2:
          switch (xiangmu) {
            case 1:
              this.isdrawdisplay = true;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;
            case 2:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;
            case 3:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = true;
              this.issortdisplay = false;
              break;
            case 9:
            case 10:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = true;
              this.issortdisplay = false;
              break;

            case 11:
              this.isdrawdisplay = true;
              this.iszhengmadisplay = true;
              this.issortdisplay = true;
              break;
            case 12:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = true;
              this.issortdisplay = true;
              break;
          }
          break;
        case 3:
          switch (xiangmu) {
            case 1:
              this.isdrawdisplay = true;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;
            case 2:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = false;
              this.issortdisplay = false;
              break;
            case 3:
              this.isdrawdisplay = true;
              this.iszhengmadisplay = false;
              this.issortdisplay = true;
              break;

            case 4:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = false;
              this.issortdisplay = false;
              break;

            case 5:
              this.isdrawdisplay = false;
              this.iszhengmadisplay = false;
              this.issortdisplay = false;
              break;
          }
          break;
      }
    },
    ZhengMaClick(zhengma) {
      this.zhengma = zhengma;

      if (this.type == 2) {
        let temp = "";
        switch (this.zhengma) {
          case 0:
            temp = this.titles.zm1;
            break;
          case 1:
            temp = this.titles.zm2;
            break;
          case 2:
            temp = this.titles.zm3;
            break;
          case 3:
            temp = this.titles.zm4;
            break;
          case 4:
            temp = this.titles.zm5;
            break;
          case 5:
            temp = this.titles.zm6;
            break;
        }
        switch (this.subtype) {
          case 3:
            this.title = temp + this.titles.tzs;
            break;
          case 9:
            this.title = temp + this.titles.mlmyl;
            break;
          case 10:
            this.title = temp + this.titles.mbsyl;
            break;
          case 11:
            this.title = temp + this.titles.trm;
            break;
          case 12:
            this.title = temp + this.titles.tyl;
            break;
        }
      }
      this.TongJi(
        this.type,
        this.subtype,
        this.count,
        this.sort,
        this.zhengma,
        this.curindex,
        this.title
      );
    },
    SortClick(sort) {
      this.sort = sort;
      console.log("sort:" + this.sort);
      this.TongJi(
        this.type,
        this.subtype,
        this.count,
        this.sort,
        this.zhengma,
        this.curindex,
        this.title
      );
    },
    DrawClick(count) {
      this.count = count;
      this.TongJi(
        this.type,
        this.subtype,
        this.count,
        this.sort,
        this.zhengma,
        this.curindex,
        this.title
      );
    },
    groupChart: function(title, cdata, type, zhengma, useColor) {
      console.log("zhengma:" + zhengma);
      var data = cdata;
      console.log(useColor);
      data = data[zhengma];

      this.chartOptions = {
        chart: {
          type: "column",
        },
        title: {
          text: title,
        },
        subtitle: {
          text: "点击下方历史最高,可对比数据",
          style: {
            color: "#bdbdbd",
          },
        },
        xAxis: {
          categories: data.xdata,
        },

        yAxis: [
          {
            //allowDecimals: false,
            min: 0,
            title: {
              text: this.titles.ylcs,
            },
            //stackLabels: {  // 堆叠数据标签
            //    enabled: true,
            //    style: {
            //        fontWeight: 'bold',
            //        color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
            //    }
            //}
            opposite: true,
          },
        ],
        legend: {
          shadow: false,
        },
        tooltip: {},
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            grouping: false,
            shadow: false,
            groupPadding: 0,
            dataLabels: {
              enabled: true,
              //inside: true
            },

            borderWidth: 0,
          },
        },
        series: [
          {
            name: this.titles.lszg,
            data: data.data1,
            //pointPadding: 0.1, // 通过 pointPadding 和 pointPlacement 控制柱子位置
            //pointPlacement: -0.2,
            //color: "#E0E0E0",
            color: "#BDBDBD",
            visible: false,

            //stack: 'male' // stack 值相同的为同一组
          },
          {
            name: this.titles.dqyl,
            data: data.data2,
            //pointPadding: 0.2,
            //pointPlacement: -0.2,
            showInLegend: false,
            //stack: 'male'
          },
        ],
      };
      HighCharts.chart("chartcontainer", this.chartOptions);
    },
    showColumn: function(title, cdata, type, zhengma, fun, useColor) {
      //console.log("开始绘图");
      if (title == undefined) {
        title = "";
      }
      console.log(useColor);
      var data = cdata;

      data = data[zhengma];

      for (var xData = [], chartData = [], i = 0; i < data.length; i++) {
        xData.push(data[i].Name);
        var obj = {
          name: data[i].Name,
          y: data[i].Count,
        };
        // useColor && (obj.color = getColor(data[i].Name)),
        chartData.push(obj);
      }
      this.chartOptions = {
        chart: {
          //renderTo: "chartcontainer",
          defaultSeriesType: "column",
        },
        title: {
          text: title,
          style: {
            fontWeight: "bold",
          },
        },
        xAxis: {
          categories: xData,
          labels: {
            style: {
              fontWeight: "bold",
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          //enable: !!fun,
          // formatter: fun,
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: !0,
              style: {
                fontWeight: "bold",
              },
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "data",
            data: chartData,
          },
        ],
      };

      HighCharts.chart("chartcontainer", this.chartOptions);
    },

    simpleChart: function(title, data, type, zhengma, useColor) {
      this.showColumn(
        title,
        data,
        type,
        zhengma,
        function() {
          return this.x + ":" + this.y + "次";
          //return "次";
        },
        useColor
      );
    },

    showChartline: function(
      title,
      cdata,
      type,
      zhengma,
      ymin,
      ymax,
      fun,
      showLogo,
      colorIndex
    ) {
      var data = cdata;

      data = data[zhengma];

      for (
        var xData = [],
          chartData = [],
          color = this.chartColor[colorIndex],
          i = 0;
        i < data.length;
        i++
      ) {
        xData.push(data[i].Name);
        var obj = {
          name: data[i].Name,
          y: data[i].Count,
        };
        chartData.push(obj);
      }
      this.chartOptions = {
        chart: {
          // renderTo: "container",
          defaultSeriesType: "spline",
        },
        title: {
          text: title,
          style: {
            "font-weight": "bold",
          },
        },
        xAxis: {
          categories: xData,
          labels: {
            style: {
              fontWeight: "bold",
            },
          },
        },
        yAxis: {
          min: ymin,
          max: ymax,
          tickPixelInterval: 50,
          title: {
            text: "",
          },
        },
        legend: {
          enabled: !1,
        },
        tooltip: {
          enable: !!fun,
          formatter: fun,
        },
        plotOptions: {
          spline: {
            pointPadding: 0,
            borderWidth: 0,
            dataLabels: {
              enabled: !0,
            },
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "data",
            data: chartData,
            color: color,
          },
        ],
      };
      HighCharts.chart("chartcontainer", this.chartOptions);
    },
  },
  created: function() {
    this.getTitle();
    this.TongJi(1, 1, 300, 1, 0, 0);
  },
};
</script>

<style scoped>
.liveTelecast_content {
  width: 100%;

  margin: 0 auto;
  display: block;
}

.liveTelecast_content .hot-drawing {
  margin-bottom: 0px;
}

.liveTelecast_content .liveTelecast_code {
  margin-top: 0px;
  overflow: hidden;
  margin-bottom: 15px;
}

.liveTelecast_content .liveTelecast_code .liveTelecast_code-lt {
  float: left;
  background: #fdfdfd;
  width: 640px;
  padding: 0 15px 0 20px;
}

.liveTelecast_content .liveTelecast_code .liveTelecast_code-lt h2 {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video {
  width: 100%;
  height: 360px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video
  .no_h5 {
  height: 100%;
  width: 100%;
  border: 1px solid #c6c6c6;
  text-align: center;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video
  .no_h5
  img {
  margin: 120px auto 15px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .liveTelecast-video
  .no_h5
  p {
  color: #666666;
  font-size: 16px;
}

.liveTelecast_content .liveTelecast_code .liveTelecast_code-lt .replicating {
  height: 35px;
  line-height: 35px;
  overflow: hidden;
  padding: 22px 0 15px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .replicating
  input.input-text {
  display: block;
  float: left;
  width: 380px;
  padding: 0 10px;
  height: 33px;
  line-height: 35px\0;
  /* vertical-align: middle; */
  border: 1px solid #ccc;
  background: none;
  border-radius: 2px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-lt
  .replicating
  button {
  float: left;
  width: 110px;
  height: 35px;
  line-height: normal;
  border-radius: 2px;
  margin-left: 9px;
}

.liveTelecast_content .liveTelecast_code .liveTelecast_code-rg {
  float: right;
  width: 480px;
  padding: 0 15px;
  background: #fdfdfd;
  height: 475px;
}

.liveTelecast_content .liveTelecast_code .liveTelecast_code-rg .code-rg-title {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #eee;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title
  .rg-title-left {
  line-height: 50px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title
  .year {
  position: absolute;
  right: 6px;
  top: 8px;
  text-align: center;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-title
  .year.search_box2
  .choose
  .text {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-coneent {
  width: 100%;
  height: 400px;
  margin: 10px 0;
  overflow: auto;
}

.liveTelecast_content .liveTelecast_code .liveTelecast_code-rg .code-ul li {
  margin: 0 10px 15px 0;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-coneent::-webkit-scrollbar {
  width: 5px;
  border-radius: 50px;
  background: #f2f2f2;
}

.liveTelecast_content
  .liveTelecast_code
  .liveTelecast_code-rg
  .code-rg-coneent::-webkit-scrollbar-thumb {
  background: #cecece;
  border-radius: 50px;
}

.statistics_code {
  background: #fdfdfd;
  overflow: hidden;
  padding: 20px 0px;
}

.statistics_code .stat_history_content {
  width: 100%;
  min-width: 100%;
}

.statistics_code .stat_history_content .history-code {
  padding: 0;
}

.statistics_code .title-conent {
  padding: 20px;
  box-sizing: border-box;
  background: #fafafa;
}

.statistics_code .highcharts {
  overflow: hidden;
}

.statistics_code .stat_menu {
  padding: 0 20px;
}

.statistics_code .stat_menu li {
  width: 100%;
  line-height: 35px;
  overflow: hidden;
}

.statistics_code .stat_menu li .name {
  float: left;
  width: 60px;
  color: #2c2c2c;
  font-weight: bold;
  font-family: 微软雅黑;
  font-size: 14px;
}

.statistics_code .stat_menu li .cont {
  float: left;
  text-align: left;
  /*width: 90%;*/
}

.statistics_code .stat_menu li .cont a {
  display: block;
  width: auto;
  float: left;
  height: 30px;
  line-height: 30px;
  position: relative;
  border-radius: 3px;
  border: 1px solid #efefef;
  padding: 0 10px;
  margin: 3px 15px 5px 0;
  background: #fdfdfd;
}

.statistics_code .stat_menu li .cont a:hover,
.statistics_code .stat_menu li .cont a.active,
.statistics_code .stat_menu li .cont a.select {
  border: 1px solid #ea5c38;
  background: #ea5c38;
  color: #fff !important;
}

.statistics_code .stat_menu li .cont .cur {
  color: #ea5c38;
}

.statistics_code .stat_menu li .cont .hot_tubiao {
  background: url("/images/hot.png") no-repeat;
  display: inline-block;
  height: 16px;
  width: 23px;
  position: absolute;
  top: -4px;
  right: -11px;
}

.statistics_code .stat_menu li .cont .new_tubiao {
  background: url("/images/new.png") no-repeat;
  display: inline-block;
  height: 16px;
  width: 23px;
  position: absolute;
  top: -4px;
  right: -11px;
}

.statistics_code .tableList {
  margin-top: 10px;
}

.statistics_code .tableList .list {
  padding: 0 20px;
}

.statistics_code .tableList .title-two {
  padding: 0 10px;
  color: #ef642d;
  background: #fffae4;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  font-size: 0.8em;
}

.statistics_code .tableList .title-two img {
  margin: 0 5px -3px 2px;
}

.statistics_code .tableList .title {
  background: #eeeeee;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  width: 100% !important;
  font-size: 14px;
  display: flex;
  align-content: space-between;
}
.statistics_code .tableList .title .draw {
  flex: 1.2;
}
.statistics_code .tableList .title .zhengma {
  flex: 1;
}
.statistics_code .tableList .title .sort {
  flex: 1;
}
.statistics_code .tableList .title input {
  margin-left: 4px;
}
span.f1 {
  margin-left: 5px;
}
.statistics_code .tableList .title .rg {
  margin-left: 10px;

  padding: 3px 0;
  line-height: 1px;
}

.statistics_code .tableList .title li {
  float: left;
  margin-left: 10px;
}
.statistics_code .tableList .title li:hover {
  color: #eb6338 !important;
  cursor: pointer;
}
.statistics_code .tableList .title li:active {
  color: #eb6338 !important;
}
.statistics_code .tableList .title li:active {
  color: #eb6338 !important;
}
.statistics_code .tableList .title li label:hover {
  color: #eb6338 !important;
}
.statistics_code .tableList .title a:hover {
  color: #eb6338 !important;
}

.zhengmate_sort {
  float: right;
  width: 250px;
  margin-top: -4px;
  margin-right: 511px;
}

.zhengmate_qishu {
  float: left;
  margin-left: 408px;
  margin-top: -40px;
}

.zhengmate_fl {
  margin-left: 330px;
  margin-top: -3px;
}

.statistics_code .tableList .title .rg input {
  /* float: left;*/
  height: 15px;
  line-height: 25px;
  border: 1px solid #bbb;
  width: 20px;
  padding: 0 5px;
  margin: 5px 5px;
}

.statistics_code .tableList .title .rg label {
  /* float: left;*/
  height: 15px;
  line-height: 25px;
  /* border: 1px solid #bbb; */
  width: 30px;
  border: none;
  /* background: #fdfdfd; */
}

.statistics_code .tableList .title .rg input radio {
  /* float: left;*/
  height: 15px;
  line-height: 25px;
  border: 1px solid #bbb;
  width: 40px;
  padding: 0 5px;
  margin: 0 5px;
  /* background: #fdfdfd; */
}

.statistics_code .tableList .title .rg button {
  height: 25px;
  border-radius: 3px;
  margin-left: 3px;
}

.statistics_code .tableList .one {
  width: 100%;
  border: 1px solid #cbcbcb;
}

.statistics_code .tableList .two .title {
  border-bottom: 1px solid #ccc;
}

.statistics_code .tableList .three .comment-title {
  text-align: center;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
}

.statistics_code .tableList .three tr.title td {
  border-bottom: 1px solid #ccc;
  height: 40px;
}

.statistics_code .tableList .three td {
  height: 50px;
}

.statistics_code .tableList .four .tableMargin {
  margin-bottom: 20px;
}

.statistics_code .tableList .four .four-title {
  overflow: hidden;
  margin-bottom: 10px;
  background: #f8f8f8;
  padding: 5px 0;
  height: 45px;
  line-height: 25px;
}

.statistics_code .tableList .four .four-title label {
  cursor: pointer;
}

.statistics_code .tableList .four .four-title .title-name {
  float: left;
  height: 30px;
  line-height: 30px;
  margin-top: 6px;
  padding-left: 20px;
}

.statistics_code .tableList .four .four-title ul {
  float: left;
  overflow: hidden;
  margin-top: -10px;
}

.statistics_code .tableList .four .four-title ul li {
  float: left;
  margin: 0 6px;
  clear: none;
}

.statistics_code .tableList .four .four-title ul li .btn_div label {
  display: inline-block;
  width: 60px;
  text-align: center;
  margin: 0px;
  border-radius: 3px;
  clear: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  color: #333;
}

.statistics_code .tableList .four .four-title ul li .btn_div label:hover {
  border-color: #ec6337;
  color: #ec6337;
}

.statistics_code .tableList .four .four-title ul li .btn_div label.active {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.statistics_code
  .tableList
  .four
  .four-title
  ul
  li
  .btn_div
  label.active:hover {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.statistics_code .tableList .four .four-title ul li input[type="checkbox"] {
  cursor: pointer;
  height: 0px;
  width: 0px;
  margin: 0px;
  display: none;
}

.statistics_code .tableList .four .four-title ul li .botrg {
  margin-top: 5px;
  width: 1px;
  height: 20px;
  background-color: #bbb;
}

.statistics_code .tableList .four .four-title ul li button {
  float: left;
  padding: 0 10px;
  height: 24px;
  line-height: normal;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 0.8em;
  margin-top: 3px;
  background: none;
  color: #686868;
}

.statistics_code .tableList .four .four-title ul li .clear_btn {
  cursor: pointer;
  background: #41b45f;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  width: 70px;
  border: none;
  margin-top: 0px;
}

.statistics_code .tableList .four .four-title ul li .clear_btn:hover {
  background: #30a04d;
}

.statistics_code .tableList .four .list table {
  margin-bottom: 10px;
}

.statistics_code .tableList .four .list table:nth-last-child(1) {
  margin-bottom: 0;
}

.statistics_code .tableList .four .list .title {
  border: 1px solid #ccc;
  background: #f0f0f0;
  font-size: 0.8em;
  border-bottom: none;
}

.statistics_code .tableList .four .list table,
.statistics_code .tableList .four .list td,
.statistics_code .tableList .four .list th {
  border: 1px solid #ccc;
}

.statistics_code .tableList .four .list td,
.statistics_code .tableList .four .list th {
  border-top: none;
  border-bottom: none;
}

.four .list .tdSpan td span {
  display: block;
  width: 100%;
  text-align: center;
}

.four .list .odd {
  color: blue !important;
}
.four .list .even {
  color: red !important;
}

.statistics_code .tableList .five .highcharts-fl {
  height: 400px;
  width: 30px;
}

.statistics_code .tableList .five .highcharts-fl .p1,
.statistics_code .tableList .five .highcharts-fl .p2 {
  width: 100%;
  text-align: center;
  color: #fff;
}

.statistics_code .tableList .five .highcharts-fl .heig2 {
  height: 200px;
}

.statistics_code .tableList .five .highcharts-fl .heig3 {
  height: 133px;
}

.statistics_code .tableList .five .highcharts-fl .heig4 {
  height: 100px;
}

.statistics_code .tableList .five .highcharts-fl .padT {
  display: inline-block;
  width: 100%;
  padding-top: 76px;
}

.statistics_code .tableList .five .highcharts-fl .padT4 {
  display: inline-block;
  width: 100%;
  padding-top: 40px;
}

.statistics_code .tableList .five .highcharts-fl .padT3 {
  display: inline-block;
  width: 100%;
  padding-top: 50px;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_01 {
  background: url("/images/feiqin-bg.png") 0 50% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_02 {
  background: url("/images/zoushou-bg.png") 0 50% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_03 {
  background: url("/images/shenxiao_bg01.png") 0 50% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_04 {
  background: url("/images/shenxiao_bg02.png") 0 50% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_05 {
  background: url("/images/td-bg01.png") 40% 50% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_06 {
  background: url("/images/td-bg02.png") 80% 50% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_07 {
  background: url("/images/sjsx-bg05.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_08 {
  background: url("/images/sjsx-bg07.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_09 {
  background: url("/images/sjsx-bg06.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_10 {
  background: url("/images/sjsx-bg08.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_11 {
  background: url("/images/qinqi-bg01_03.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_12 {
  background: url("/images/qinqi-bg02_03.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_13 {
  background: url("/images/qinqi-bg03_03.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_14 {
  background: url("/images/qinqi-bg04_03.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_15 {
  background: url("/images/sssx_01.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_16 {
  background: url("/images/sssx_02.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .highcharts-fl .backUrl_17 {
  background: url("/images/sssx_03.png") 0 20% no-repeat;
}

.statistics_code .tableList .five .backLan {
  background-color: #0099ff !important;
  color: #fff;
}

.statistics_code .tableList .five .backChan {
  background-color: #ff7200 !important;
  color: #fff;
}

.statistics_code .tableList .five .backLi {
  background-color: #abc808 !important;
  color: #fff;
}

.statistics_code .tableList .five .backCLi {
  background-color: #009900 !important;
  color: #fff;
}

.statistics_code .tableList .five .backHo {
  background-color: #eb2222 !important;
  color: #fff;
}

.statistics_code .tableList .five .backHuan {
  background-color: #f2bc16 !important;
  color: #fff;
}

.statistics_code .tableList .five .rg li {
  float: left;
  margin-left: 10px;
  font-size: 0.9em;
}

.statistics_code .tableList .five .rg li:hover {
  color: #eb6338 !important;
  cursor: pointer;
}

.statistics_code .tableList .five .conent {
  padding: 0 30px;
}

.statistics_code .tableList .five .conent table {
  border: none;
}

.statistics_code .tableList .five .conent table,
.statistics_code .tableList .five .conent td,
.statistics_code .tableList .five .conent th {
  border: 1px solid #ccc;
  height: 30px;
}

.statistics_code .tableList .five .conent h3 {
  text-align: center;
  height: 60px;
  line-height: 60px;
}

.statistics_code .tableList .five .conent .clearBoth table {
  float: left;
  text-align: center;
  font-size: 0.9em;
  margin: 0;
}

.statistics_code .tableList .five .conent .conent-two {
  margin-bottom: 20px;
}

.statistics_code .tableList .five .conent .conent-two .poultry {
  background: url("/images/feiqin-bg02.png") 45% 0 no-repeat;
  background-color: #019bf7;
  color: #fff;
}

.statistics_code .tableList .five .conent .conent-two .beast {
  background: url("/images/zoushou-bg02.png") 38% 2px no-repeat;
  background-color: #ff7300;
  color: #fff;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_01 {
  background: url("/images/shengxiao-bg04.png") 43% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_02 {
  background: url("/images/shengxiao-bg03.png") 43% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_03 {
  background: url("/images/td-bg03.png") 40% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_04 {
  background: url("/images/td-bg04.png") 40% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_05 {
  background: url("/images/sjsx-bg01.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_06 {
  background: url("/images/sjsx-bg03.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_07 {
  background: url("/images/sjsx-bg02.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_08 {
  background: url("/images/sjsx-bg04.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_09 {
  background: url("/images/sssx_04.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_10 {
  background: url("/images/sssx_05.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .thUrl_11 {
  background: url("/images/sssx_06.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .xqch .thUrl_05 {
  background: url("/images/qinqi-bg01_03.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .xqch .thUrl_06 {
  background: url("/images/qinqi-bg02_03.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .xqch .thUrl_07 {
  background: url("/images/qinqi-bg03_03.png") 35% 1px no-repeat;
}

.statistics_code .tableList .five .conent .conent-two .xqch .thUrl_08 {
  background: url("/images/qinqi-bg04_03.png") 35% 1px no-repeat;
}

.statistics_code .tableList .six h3 {
  text-align: center;
  border-top: 1px solid #efefef;
  height: 40px;
  line-height: 50px;
}

.statistics_code .tableList .six .projectile-conent {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
}

.statistics_code .tableList .six .projectile-conent .fl {
  width: 33.33333333336%;
  padding-right: 10px;
  box-sizing: border-box;
}

.statistics_code .tableList .six .projectile-conent .fl td {
  height: 35px;
}

.statistics_code .tableList .six .projectile-conent .nonePadding {
  padding: 0;
}

.statistics_code .tableList .six .projectile-conent .borderBotton {
  border-bottom: 1px solid #f3f3f3;
}

.statistics_code .tableList .six .projectile-conent .title-ul {
  display: table;
  width: auto;
  text-align: center;
  clear: both;
  overflow: hidden;
  margin: 0 auto;
}

.statistics_code .tableList .six .projectile-conent .title-ul li {
  float: left;
  width: auto;
  padding: 0 25px;
  text-align: center;
  background: #fff0eb;
  color: #f45540;
  margin-right: 20px;
  border-radius: 3px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}

.statistics_code .tableList .six .projectile-conent .title-ul li.select {
  background: #ec6337;
  color: #fff;
}

.statistics_code .tableList .six .projectile-conent .table-title {
  text-align: center;
  background: #f1f1f1;
  border: 1px solid #ccc;
  height: 35px;
  line-height: 35px;
  margin-bottom: -1px;
}
.f1 {
  float: left;
}
.f2 {
  float: left;
}

.statistics_code .tableList .six .projectile-conent .tow table,
.statistics_code .tableList .six .projectile-conent .tow td,
.statistics_code .tableList .six .projectile-conent .tow th {
  border: 1px solid #ccc;
}

.statistics_code .tableList .six .projectile-conent .tow ul {
  overflow: hidden;
}

.statistics_code .tableList .six .projectile-conent .tow ul li {
  float: left;
  width: 40px;
}

.statistics_code .tableList .six .projectile-conent .marginTop20 {
  margin-top: 20px;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .one {
  margin-top: 20px;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .colorRed {
  color: #d22d34 !important;
  font-weight: bold;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .colorLan {
  color: #217fbd !important;
  font-weight: bold;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .colorLin {
  color: #499530 !important;
  font-weight: bold;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .tow table,
.statistics_code .tableList .six .projectile-conent .title-ul-one .tow td,
.statistics_code .tableList .six .projectile-conent .title-ul-one .tow th {
  border: 1px solid #ccc;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .tow ul {
  overflow: hidden;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one .tow ul li {
  float: left;
  width: 40px;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one table,
.statistics_code .tableList .six .projectile-conent .title-ul-one td,
.statistics_code .tableList .six .projectile-conent .title-ul-one th {
  border: 1px solid #ccc;
}

.statistics_code .tableList .six .projectile-conent .title-ul-one td,
.statistics_code .tableList .six .projectile-conent .title-ul-one th {
  border-bottom: none;
  border-top: none;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.statistics_code .tableList .six .projectile-conent .title-ul-tow tr {
  height: 35px;
  line-height: 35px;
}

.statistics_code .tableList .six .projectile-conent .title-ul-tow .tow li {
  width: 99.5px;
}

.statistics_code .tableList .six .projectile-conent .title-ul-therr {
  width: 100%;
  overflow: hidden;
}

.statistics_code .tableList .six .projectile-conent .title-ul-therr div {
  float: left;
  width: 50%;
  padding-right: 10px;
  box-sizing: border-box;
}

.statistics_code .tableList .six .projectile-conent .title-ul-therr div tr {
  height: 35px;
  line-height: 35px;
}

.statistics_code
  .tableList
  .six
  .projectile-conent
  .title-ul-therr
  div
  .tow
  li {
  width: 33.3333333%;
}

.statistics_code
  .tableList
  .six
  .projectile-conent
  .title-ul-therr
  div.title-div-therr
  .tow
  li {
  width: 25%;
}

.statistics_code
  .tableList
  .six
  .projectile-conent
  .title-ul-therr
  div.title-div-tow {
  padding: 0;
}

.statistics_code .tableList .six .projectile-conent .title-ul-therr table,
.statistics_code .tableList .six .projectile-conent .title-ul-therr td,
.statistics_code .tableList .six .projectile-conent .title-ul-therr th {
  border: 1px solid #ccc;
}

.statistics_code .tableList .four .tableMargin {
  margin-bottom: 20px;
}

.statistics_code .tableList .four .four-title {
  overflow: hidden;
  margin-bottom: 10px;
  background: #f8f8f8;
  padding: 5px 0;
}

.statistics_code .tableList .four .four-title label {
  cursor: pointer;
}

.statistics_code .tableList .four .four-title .title-name {
  float: left;
  height: 30px;
  line-height: 30px;
  margin-top: 6px;
  padding-left: 20px;
}

.statistics_code .tableList .four .four-title ul {
  float: left;
  overflow: hidden;
}

.statistics_code .tableList .four .four-title ul li {
  float: left;
  margin: 6px;
  clear: none;
}

.statistics_code .tableList .four .four-title ul li .btn_div label {
  display: inline-block;
  width: 68px;
  text-align: center;
  margin: 0px;
  border-radius: 3px;
  clear: none;
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  border: 1px solid #ccc;
  color: #333;
}

.statistics_code .tableList .four .four-title ul li .btn_div label:hover {
  border-color: #ec6337;
  color: #ec6337;
}

.statistics_code .tableList .four .four-title ul li .btn_div label.active {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.statistics_code
  .tableList
  .four
  .four-title
  ul
  li
  .btn_div
  label.active:hover {
  background: #ec6337;
  color: #fff;
  border-color: #ec6337;
}

.statistics_code .tableList .four .four-title ul li input[type="checkbox"] {
  cursor: pointer;
  height: 0px;
  width: 0px;
  margin: 0px;
  display: none;
}

.statistics_code .tableList .four .four-title ul li .botrg {
  margin-top: 5px;
  width: 1px;
  height: 20px;
  background-color: #bbb;
}

.statistics_code .tableList .four .four-title ul li button {
  float: left;
  padding: 0 10px;
  height: 24px;
  line-height: normal;
  border: 1px solid #ccc;
  border-radius: 2px;
  font-size: 0.8em;
  margin-top: 3px;
  background: none;
  color: #686868;
}

.statistics_code .tableList .four .four-title ul li .clear_btn {
  cursor: pointer;
  background: #41b45f;
  border-radius: 4px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  width: 70px;
  border: none;
  margin-top: 0px;
}

.statistics_code .tableList .four .four-title ul li .clear_btn:hover {
  background: #30a04d;
}

.statistics_code .tableList .four .list table {
  margin-bottom: 10px;
}

.statistics_code .tableList .four .list table:nth-last-child(1) {
  margin-bottom: 0;
}

.statistics_code .tableList .four .list .title {
  border: 1px solid #ccc;
  background: #f0f0f0;
  font-size: 0.8em;
  border-bottom: none;
}

.statistics_code .tableList .four .list table,
.statistics_code .tableList .four .list td,
.statistics_code .tableList .four .list th {
  border: 1px solid #ccc;
}

.statistics_code .tableList .four .list td,
.statistics_code .tableList .four .list th {
  border-top: none;
  border-bottom: none;
}

.statistics_code .tableList .four .list .tdSpan td span {
  display: block;
  width: 100%;
  text-align: center;
}

.statistics_code .tableList .four .list .odd {
  color: blue !important;
}

.statistics_code .tableList .four .list .even {
  color: red !important;
}
</style>
