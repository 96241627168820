<template>
  <div class="list">
    <div class="two noneBor">
      <div class="title-two" style="border-bottom: none">
        <img src="//resms.6h-cdn.com/dist/images/web/default/img/Sigh.png" />
        {{ tishi }}
      </div>
      <table
        width="100%"
        border="1"
        align="center"
        cellpadding="0"
        cellspacing="0"
      >
        <tbody>
          <tr align="center" class="title">
            <td width="122">{{ qh }}</td>
            <td>{{ sxs[0] }}</td>
            <td>{{ sxs[1] }}</td>
            <td>{{ sxs[2] }}</td>
            <td>{{ sxs[3] }}</td>
            <td>{{ sxs[4] }}</td>
            <td>{{ sxs[5] }}</td>
            <td>{{ sxs[6] }}</td>
            <td>{{ sxs[7] }}</td>
            <td>{{ sxs[8] }}</td>
            <td>{{ sxs[9] }}</td>
            <td>{{ sxs[10] }}</td>
            <td>{{ sxs[11] }}</td>
          </tr>

          <tr
            v-for="(row, index) in list"
            :key="index"
            align="center"
            :class="{ backAsh: index % 2 != 0 }"
          >
            <td>{{ row.draw }}</td>
            <td>{{ row.s1 }}</td>
            <td>{{ row.s2 }}</td>
            <td>{{ row.s3 }}</td>
            <td>{{ row.s4 }}</td>
            <td>{{ row.s5 }}</td>
            <td>{{ row.s6 }}</td>
            <td>{{ row.s7 }}</td>
            <td>{{ row.s8 }}</td>
            <td>{{ row.s9 }}</td>
            <td>{{ row.s10 }}</td>
            <td>{{ row.s11 }}</td>
            <td>{{ row.s12 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      titles: {},
      tishi: "",
      qh: "",
      sxs: [],
    };
  },
  methods: {
    getData() {
      let url = "/shengxiaodistribut/Get";
      this.axios
        .get(url)
        .then((res) => {
          console.log(res);
          let result = res.data;
          if (result.code == "1") {
            result = JSON.parse(this.Decrypt(result.data));
            this.list = result.list;
            this.qh = result.qh;
            this.tishi = result.tishi;
            this.sxs = result.sxs;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  beforeMount: function() {
    this.getData();
  },
};
</script>

<style scoped>
/* .statistics_code .tableList .two .title {
  border-bottom: 1px solid #ccc;
} */
.statistics_code .tableList .title-two {
  padding: 0 10px;
  color: #ef642d;
  background: #fffae4;
  height: 40px;
  line-height: 40px;
  border: 1px solid #ccc;
  font-size: 0.8em;
}
.statistics_code .tableList .title {
  background: #eeeeee;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  width: 100% !important;
  font-size: 14px;
}
table {
  border-collapse: collapse;
  border-spacing: 1px;
}
</style>
