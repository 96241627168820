<template>
  <div class="page-wrap">
    <div class="liveTelecast_content">
      <!--广告-->

      <div class="advertisement_frame">
        <ul class="Advertisement" id="ad_shipin_top" style="display:none;"></ul>
      </div>
      <div class="liveTelecast_code">
        <iframe
          class="shipin"
          src="https://www.luckyball.info/stream/?s=mark6&token=nGNXvnuARxamJhJ3SL6dAUuwzusuo73DPst48AnPeHpqK3s9pHwESD2kk7DZePVL"
          width="750px"
          height="500px"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    var isao = ref(false);
    return {
      isao,
    };
  },
};
</script>

<style scoped>
.page-wrap {
  width: 100%;
  min-height: 100%;
  text-align: left;
  display: flex;
}
.shipin {
  margin: 100px 15%;
  min-height: 500px;

  border: none;
}
.page-wrap .liveTelecast_content {
  width: 100%;
  /*min-width: 1200px;*/
  margin: 0 auto;
  display: block;
}

.page-wrap .liveTelecast_content .hot-drawing {
  margin-bottom: 0px;
}

.page-wrap .liveTelecast_content .liveTelecast_code {
  margin-top: 0px;
  overflow: hidden;
  margin-bottom: 15px;
}

.page-wrap .liveTelecast_content .liveTelecast_code .liveTelecast_code-lt {
  float: left;
  background: #fdfdfd;
  width: 640px;
  padding: 0 15px 0 20px;
}
.live {
  width: 1169px;
  height: 368px;
  margin: 0 auto 20px;
  position: absolute;
  top: -369px;
  padding: 0;
  overflow: hidden;
  background-color: #000;
}
.live .liveSon {
  width: 640px;
  height: 368px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.live .liveSon .cover {
  width: 640px;
  height: 368px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
  background-color: #000;
}
.notice {
  height: 36px;
  line-height: 36px;
  width: 700px;
  font-size: 18px;
  margin: 0 auto;
  color: #fff;
  padding-left: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
}

/* a, abbr, acronym, addr */
</style>
